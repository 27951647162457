<template>
  <div class="has-header">
    <div class="header main">
      <div class="align-left">
        <dateselectionheader v-show="subPage === 'export'" :label="dateDisplay" @PrevDate="modDate('sub')" @NextDate="modDate('add')" />
      </div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons" />
      </div>
      <div class="align-right">
        <headerbuttonstack :state="sideButtons" />
      </div>
    </div>
    <div class="scrollbar-container export-builder" v-if="!loading && !exporting && subPage === 'export'">
      <labelc type="header title" v-bind:text="titleText" style="font-size: 40px" />

      <!-- Einstellungen -->
      <div class="panel settings" id="settings" v-if="!hiddenStates.exportsettings" v-bind:style="{ order: orderStates.exportsettings }">
        <div class="fa-stack cancel" v-on:click="hiddenStates.exportsettings = true">
          <i class="fas fa-circle fa-stack-2x"></i>
          <i class="fas fa-times fa-stack-2x"></i>
        </div>
        <!--
        <buttonc
          icon="fa-arrows-alt"
          class="drag-marker"
          type="icon-overlay round"
          style="border-radius: 100px"
          @mousedown="DragStart('settings')"
        />-->
        <labelc
          v-if="duplicateEmployees.length > 0"
          type=""
          text="Mitarbeiter mehrfach vorhanden"
          :icon-right="{
            type: 'icon-overlay',
            icon: 'fa-info-circle',
            styleButton: { marginLeft: '0px' },
            tooltip: GetEmployeeInfoTooltip(),
          }"
          style="z-index: 10; position: absolute; left: -220px !important; right: 0 !important; margin: auto; top: 45px; width: fit-content; height: 40px; color: #e51550"
        />

        <labelc
          v-if="missingPersonalIds.length > 0"
          type=""
          text="Personalnummer fehlt"
          :icon-right="{
            type: 'icon-overlay',
            icon: 'fa-info-circle',
            styleButton: { marginLeft: '0px' },
            tooltip: GetEmployeeMissingPersoTooltip(),
          }"
          style="z-index: 10; position: absolute; left: 220px !important; right: 0 !important; margin: auto; top: 45px; width: fit-content; height: 40px; color: #e51550"
        />

        <labelc
          type="header title"
          text="Exporteinstellungen"
          :icon-right="{
            styleButton: {
              'border-radius': '100px',
              width: '25px',
              marginTop: '5px',
            },
            type: 'icon-overlay',
            icon: collapseStates.exportsettings ? 'fa-angle-down' : 'fa-angle-up',
            click: () => {
              collapseStates.exportsettings = !collapseStates.exportsettings;
            },
          }"
        />
        <div style="text-align: left; padding: 7px; color: #e51550" v-if="hiddenStores > 0">
          <small v-if="hiddenStores == 1">{{ hiddenStores }} Kostenstelle wird aufgrund der Filter-Einstellung nicht angezeigt</small>
          <small v-if="hiddenStores > 1">{{ hiddenStores }} Kostenstellen werden aufgrund der Filter-Einstellungen nicht angezeigt</small>
        </div>
        <div
          class="panel-container"
          v-bind:style="{
            display: !collapseStates.exportsettings && !isDragging ? '' : 'none',
          }"
        >
          <datatablesimple style="flex-basis: calc(50% - 20px)" :state="settingsCompanyState" />
          <datatablesimple style="flex-basis: calc(50% - 20px)" :state="settingsHeaderState" />
          <datatablesimple v-if="['monthly', 'individual-wagetypes'].includes($parent.mode)" style="flex-basis: calc(50% - 20px)" :state="settingsMonthlyAdditionalState" />
          <datatablesimple v-if="$parent.mode === 'daily'" style="flex-basis: calc(50% - 20px)" :state="settingsDailyAdditionalState" />
          <datatablesimple v-if="$parent.mode === 'personnelProvisions'" style="flex-basis: calc(50% - 20px)" :state="settingsPersonnelProvisionsAdditionalState" />
        </div>
      </div>

      <!-- Verfügbare Beschäftigungsmodelle -->
      <div class="panel employmentModels" id="employmentModels" v-if="$parent.mode === 'individual-wagetypes' && !hiddenStates.employmentModels" v-bind:style="{ order: orderStates.employmentModels }">
        <div class="fa-stack cancel" v-on:click="hiddenStates.employmentModels = true">
          <i class="fas fa-circle fa-stack-2x"></i>
          <i class="fas fa-times fa-stack-2x"></i>
        </div>
        <!--
        <buttonc
          icon="fa-arrows-alt"
          class="drag-marker"
          type="icon-overlay round"
          style="border-radius: 100px"
          @mousedown="DragStart('employmentModels')"
        />-->
        <labelc
          type="header title"
          text="Verfügbare Beschäftigungsmodelle"
          :icon-right="{
            styleButton: {
              'border-radius': '100px',
              width: '25px',
              marginTop: '5px',
            },
            type: 'icon-overlay',
            icon: collapseStates.employmentModels ? 'fa-angle-down' : 'fa-angle-up',
            click: () => {
              collapseStates.employmentModels = !collapseStates.employmentModels;
            },
          }"
        />
        <div
          class="panel-container"
          v-bind:style="{
            display: !collapseStates.employmentModels && !isDragging ? '' : 'none',
          }"
          style="text-align: center"
        >
          <buttonc
            v-for="(wage, index) in employmentModels"
            :key="'MainL'+index"
            :label="wage.name"
            @click="
              () => {
                wage.checked = !wage.checked;
                somethingHasChangedWhatAffectsExportTable = true;
                saveContentLS();
              }
            "
            v-bind:class="{ selected: wage.checked }"
            style="width: calc(19% - 2.5px); height: 30px; margin: 2.5px"
            v-tooltip="'Auswählen / Abwählen'"
          />
        </div>
      </div>

      <!-- Verfügbare Lohnarten -->
      <div class="panel wageTypes" id="wageTypes" v-if="$parent.mode !== 'personnelProvisions' && !hiddenStates.wageTypes" v-bind:style="{ order: orderStates.wageTypes }">
        <div class="fa-stack cancel" v-on:click="hiddenStates.wageTypes = true">
          <i class="fas fa-circle fa-stack-2x"></i>
          <i class="fas fa-times fa-stack-2x"></i>
        </div>
        <!--
        <buttonc
          icon="fa-arrows-alt"
          class="drag-marker"
          type="icon-overlay round"
          style="border-radius: 100px"
          @mousedown="DragStart('wageTypes')"
        />-->
        <labelc
          type="header title"
          text="Verfügbare Lohnarten"
          :icon-right="{
            styleButton: {
              'border-radius': '100px',
              width: '25px',
              marginTop: '5px',
            },
            type: 'icon-overlay',
            icon: collapseStates.wageTypes ? 'fa-angle-down' : 'fa-angle-up',
            click: () => {
              collapseStates.wageTypes = !collapseStates.wageTypes;
            },
          }"
        />
        <div
          class="panel-container"
          v-bind:style="{
            display: !collapseStates.wageTypes && !isDragging ? '' : 'none',
          }"
          style="text-align: center"
        >
          <buttonc
            v-for="(wage, index) in contentMain"
            :key="'MainL'+index"
            :label="wage.name"
            @click="
              () => {
                wage.checked = !wage.checked;
                somethingHasChangedWhatAffectsExportTable = true;
                saveContentLS();
              }
            "
            v-bind:class="{ selected: wage.checked }"
            style="width: calc(19% - 2.5px); height: 30px; margin: 2.5px"
            v-tooltip="'Auswählen / Abwählen'"
          /><br /><br />
          <buttonc
            v-for="(wage, index) in contentSecondary"
            :key="'SubL'+index"
            :label="wage.name"
            @click="
              () => {
                wage.checked = !wage.checked;
                somethingHasChangedWhatAffectsExportTable = true;
                saveContentLS();
              }
            "
            v-bind:class="{ selected: wage.checked }"
            style="width: calc(19% - 2.5px); height: 30px; margin: 2.5px"
            v-tooltip="'Auswählen / Abwählen'"
          />
        </div>
      </div>

      <!-- Monatsabschlüsse -->
      <div class="panel employees" id="employees" v-if="['monthly'].includes($parent.mode) && !hiddenStates.employees" v-bind:style="{ order: orderStates.employees }">
        <div class="fa-stack cancel" v-on:click="hiddenStates.employees = true">
          <i class="fas fa-circle fa-stack-2x"></i>
          <i class="fas fa-times fa-stack-2x"></i>
        </div>
        <!--
        <buttonc
          icon="fa-arrows-alt"
          class="drag-marker"
          type="icon-overlay round"
          style="border-radius: 100px"
          @mousedown="DragStart('employees')"
        />-->
        <inputc
          class="name-filter"
          :inputProp="employeeNameFilter"
          @Update="
            (val) => {
              employeeNameFilter = val;
              $forceUpdate();
            }
          "
          :placeholder="employeeNameFilter === '' ? 'Mitarbeitername' : employeeNameFilter"
        />
        <labelc
          type="header title"
          text="MD Monatsabschlüsse"
          :icon-right="{
            styleButton: {
              'border-radius': '100px',
              width: '25px',
              marginTop: '5px',
            },
            type: 'icon-overlay',
            icon: collapseStates.employees ? 'fa-angle-down' : 'fa-angle-up',
            click: () => {
              collapseStates.employees = !collapseStates.employees;
            },
          }"
        />
        <div
          class="panel-container"
          v-bind:style="{
            display: !collapseStates.employees && !isDragging ? '' : 'none',
          }"
        >
          <datatablesimple :state="settingsEmployeesState" v-if="!collapseStates.employees" />
        </div>
      </div>

      <!-- Export Vorschau Panel -->
      <div class="panel employees" id="preview" v-if="!hiddenStates.preview" v-bind:style="{ order: orderStates.preview }">
        <div class="fa-stack cancel" v-on:click="hiddenStates.preview = true">
          <i class="fas fa-circle fa-stack-2x"></i>
          <i class="fas fa-times fa-stack-2x"></i>
        </div>
        <!--
        <buttonc
          icon="fa-arrows-alt"
          class="drag-marker"
          type="icon-overlay round"
          style="border-radius: 100px"
          @mousedown="DragStart('preview')"
        />-->
        <labelc
          type="header title"
          text="Export Vorschau"
          :icon-right="{
            styleButton: {
              'border-radius': '100px',
              width: '25px',
              marginTop: '5px',
            },
            type: 'icon-overlay',
            icon: collapseStates.preview ? 'fa-angle-down' : 'fa-angle-up',
            click: () => {
              collapseStates.preview = !collapseStates.preview;
            },
          }"
        />
        <div v-if="somethingHasChangedWhatAffectsExportTable && !buildingExportTable">
          <strong>Die Daten für die Tabelle haben sich verändert</strong><br />
          <buttonc
            label="Tabelle neu generieren"
            class=""
            type="no-border"
            @click="
              buildingExportTable = true;
              buildExportTable();
            "
          />
        </div>

        <div v-if="buildingExportTable">
          <i class="fas fa-circle-notch fa-spin"></i>
          <br /><strong>Die Tabelle wird generiert...</strong>
        </div>

        <div
          v-if="!somethingHasChangedWhatAffectsExportTable"
          class="panel-container"
          v-bind:style="{
            display: !collapseStates.preview && !isDragging ? '' : 'none',
          }"
        >
          <datatablesimple style="margin-top: 30px" :state="previewState" v-if="!collapseStates.preview" />
        </div>
      </div>
    </div>
    <div class="scrollbar-container export-builder" v-if="!loading && !exporting && subPage === 'settings'">
      <labelc type="header title" v-bind:text="settingsTitleText" style="font-size: 40px" />

      <!-- Basis Einstellungen -->
      <div class="panel settings" id="sBaseSettings">
        <labelc
          type="header title"
          text="Basiseinstellungen"
        />
        <div class="panel-container">
          <!-- enableExportViaTransferService -->
          <div class="entryBox">
            <div class="entryHeader">Direkten Export ins LV Tool aktivieren</div>
            <div class="entryData">
              <i class="fas fa-check-circle checkbox" :key="'cb-eevts'"
                 v-bind:class="{ false: !baseSettings.enableExportViaTransferService }"
                 v-on:click="settingsUpdated = true; baseSettings.enableExportViaTransferService = !baseSettings.enableExportViaTransferService"></i>
            </div>
          </div>

          <!-- enablePeriodicExport -->
          <div class="entryBox">
            <div class="entryHeader">Periodischen Export aktivieren</div>
            <div class="entryData" style="">
              <i class="fas fa-check-circle checkbox" :key="'cb-epe'"
                 v-bind:class="{ false: !baseSettings.enablePeriodicExport || !baseSettings.enableExportViaTransferService, disabled: !baseSettings.enableExportViaTransferService }"
                 v-on:click="settingsUpdated = true; baseSettings.enablePeriodicExport = !baseSettings.enablePeriodicExport"></i>
              <i v-if="baseSettings.enablePeriodicExport && baseSettings.enableExportViaTransferService && selectedSettingsId" class="fas fa-plus" style="padding-left: 20px;" v-tooltip="'Zeit hinzufügen'"
                 v-on:click="openEditPeriodicTimePopup({type: 0, dayOfMonth: 0, dayOfWeek: 0, hours: 0, minutes: 0, exportId: selectedSettingsId, exportMonth: 0, monthlyClosingRequired: false, months: []}, true)"></i>
            </div>
          </div>

          <!-- periodic export times -->
          <div v-if="baseSettings.enablePeriodicExport && baseSettings.enableExportViaTransferService" v-for="time in baseSettings.periodicExportTimes" class="entryBox">
            <div v-if="!time.editing && sExportSettings.find(e => e.id === time.exportId)" class="entryHeader" style="text-align: right; padding-right: 10px">{{ sExportSettings.find(e => e.id === time.exportId).name }}</div>
            <div v-if="!time.editing && periodicExportTimeReportMonth.find(e => e.value === time.exportMonth)" class="entryData" style="width: 100px">{{ periodicExportTimeReportMonth.find(e => e.value === time.exportMonth).label }}</div>
            <div v-if="!time.editing && periodicExportTimeTypes.find(e => e.value === time.type)" class="entryData" style="width: 100px">{{ periodicExportTimeTypes.find(e => e.value === time.type).label }}</div>
            <div v-if="!time.editing" class="entryData" style="width: 270px">{{ getPeriodicTimeLabel(time) }}</div>
            <div v-if="!time.editing && time.monthlyClosingRequired" class="entryData" style="">Nur exportieren, wenn Monat abgeschlossen ist</div>
            <div v-else-if="!time.editing" class="entryData" style="">Unabhängig von Monatsabschluss exportieren</div>
            <div v-show="!time.editing" class="entryData">
              &nbsp;
              <i v-tooltip="'Zeit bearbeiten'" class="fas fa-pen" style="padding-left: 5px;" v-on:click="openEditPeriodicTimePopup(time)/*settingsUpdated = true; time.editing = true;*/" />
            </div>
            <div v-show="!time.editing" class="entryData">
              &nbsp;
              <i v-tooltip="'Zeit löschen'" class="fas fa-trash" v-on:click="baseSettings.periodicExportTimes.splice(baseSettings.periodicExportTimes.indexOf(time), 1); settingsUpdated = true;" />
            </div>
          </div>

          <!-- enable triggered Export after monthly closing -->
          <div class="entryBox">
            <div class="entryHeader">Export bei Monatsabschluss aktivieren</div>
            <div class="entryData" style="">
              <i class="fas fa-check-circle checkbox" :key="'cb-emce'"
                 v-bind:class="{ false: !baseSettings.enableMonthlyClosingExport || !baseSettings.enableExportViaTransferService, disabled: !baseSettings.enableExportViaTransferService }"
                 v-on:click="settingsUpdated = true; baseSettings.enableMonthlyClosingExport = !baseSettings.enableMonthlyClosingExport"></i>
              <i v-if="baseSettings.enableMonthlyClosingExport && baseSettings.enableExportViaTransferService && selectedSettingsId" class="fas fa-plus" style="padding-left: 20px;" v-tooltip="'Export hinzufügen'"
                 v-on:click="openEditMonthlyClosingExportPopup({exportId: selectedSettingsId, months: [] }, true)"></i>
            </div>
          </div>

          <!-- monthly closing export settings -->
          <div v-if="baseSettings.enableMonthlyClosingExport && baseSettings.enableExportViaTransferService" v-for="time in baseSettings.monthlyClosingExportSettings" class="entryBox">
            <div v-if="sExportSettings.find(e => e.id === time.exportId)" class="entryHeader" style="text-align: right; padding-right: 10px">{{ sExportSettings.find(e => e.id === time.exportId).name }}</div>
            <div class="entryData">{{ getMonthlyClosingSettingMonths(time) }}</div>
            <div class="entryData">
              &nbsp;
              <i v-tooltip="'Einstellung bearbeiten'" class="fas fa-pen" style="padding-left: 5px;" v-on:click="openEditMonthlyClosingExportPopup(time)" />
            </div>
            <div class="entryData">
              &nbsp;
              <i v-tooltip="'Einstellung löschen'" class="fas fa-trash" v-on:click="baseSettings.monthlyClosingExportSettings.splice(baseSettings.monthlyClosingExportSettings.indexOf(time), 1); settingsUpdated = true;" />
            </div>
          </div>

        </div>
      </div>

      <!-- Export Selektor -->
      <div class="panel" id="sExportSelector">
        <labelc
          type="header title"
          text="Export Auswahl"
          :icon-right="{
            styleButton: {
              'border-radius': '100px',
              width: '25px',
              marginTop: '5px',
            },
            type: 'icon-overlay',
            icon: 'fa-pen',
            click: openEditSettingsPopup,
            tooltip: 'test',
            show: selectedSettingsId
          }"
        />
        <div class="panel-container"
             style="text-align: center"
        >

          <buttonc
              v-for="(exportSettings, index) in sExportSettings"
              :key="'selector'+index"
              :label="exportSettings.name"
              :styleLabel="{ 'overflow': 'hidden', 'text-wrap': 'nowrap'}"
              @click="
              () => {
                selectedSettingsId = exportSettings.id;
              }
            "
              v-bind:class="{ selected: selectedSettingsId === exportSettings.id }"
              style="width: calc(19% - 2.5px); height: 30px; margin: 2.5px"
              v-tooltip="'Export Einstellung auswählen'"
          />
          <buttonc
              :key="'selectorAddNew'"
              :label="'+'"
              @click="
              () => {
                openCreateNewSettingsPopup();
              }
            "
              style="width: calc(19% - 2.5px); height: 30px; margin: 2.5px"
              v-tooltip="'Neue Export Einstellung anlegen'"
          />

        </div>
      </div>

        <!-- Export Einstellungen -->
      <div v-if="selectedSettingsId" class="panel settings" id="sExportSettings">
        <labelc
          type="header title"
          text="Exporteinstellungen"
        />
        <div class="panel-container">
          <datatablesimple style="flex-basis: calc(50% - 20px)" :state="sSettingsCompanyState" />
          <datatablesimple style="flex-basis: calc(50% - 20px)" :state="sSettingsHeaderState" />
          <datatablesimple v-if="['monthly', 'individual-wagetypes'].includes($parent.mode)" style="flex-basis: calc(50% - 20px)" :state="sSettingsMonthlyAdditionalState" />
          <datatablesimple v-if="$parent.mode === 'daily'" style="flex-basis: calc(50% - 20px)" :state="sSettingsDailyAdditionalState" />
          <datatablesimple v-if="$parent.mode === 'personnelProvisions'" style="flex-basis: calc(50% - 20px)" :state="sSettingsPersonnelProvisionsAdditionalState" />
        </div>
      </div>

      <!-- Verfügbare Lohnarten -->
      <div v-if="$parent.mode !== 'personnelProvisions' && selectedSettingsId" class="panel wageTypes" id="sWageTypes">
        <labelc
          type="header title"
          text="Verfügbare Lohnarten"
        />
        <div
          class="panel-container"
          style="text-align: center"
        >
          <buttonc
            v-for="(wage, index) in sContentMain"
            :key="'MainL'+index"
            :label="wage.name"
            @click="
              () => {
                if (sExportSettings.find(e => e.id === selectedSettingsId) && sExportSettings.find(e => e.id === selectedSettingsId).selectedWageTypes.includes(wage.id)) {
                    sExportSettings.find(e => e.id === selectedSettingsId).selectedWageTypes = sExportSettings.find(e => e.id === selectedSettingsId).selectedWageTypes.filter(e => e !== wage.id);
                  } else {
                    sExportSettings.find(e => e.id === selectedSettingsId).selectedWageTypes.push(wage.id);
                  }
                settingsUpdated = true;
              }
            "
            v-bind:class="{ selected: wage.checked }"
            style="width: calc(19% - 2.5px); height: 30px; margin: 2.5px"
            v-tooltip="'Auswählen / Abwählen'"
          /><br /><br />
          <buttonc
            v-for="(wage, index) in sContentSecondary"
            :key="'SubL'+index"
            :label="wage.name"
            @click="
              () => {
                if (sExportSettings.find(e => e.id === selectedSettingsId) && sExportSettings.find(e => e.id === selectedSettingsId).selectedWageTypes.includes(wage.id)) {
                    sExportSettings.find(e => e.id === selectedSettingsId).selectedWageTypes = sExportSettings.find(e => e.id === selectedSettingsId).selectedWageTypes.filter(e => e !== wage.id);
                  } else {
                    sExportSettings.find(e => e.id === selectedSettingsId).selectedWageTypes.push(wage.id);
                  }
                settingsUpdated = true;
              }
            "
            v-bind:class="{ selected: wage.checked }"
            style="width: calc(19% - 2.5px); height: 30px; margin: 2.5px"
            v-tooltip="'Auswählen / Abwählen'"
          />
        </div>
      </div>

    </div>
    <div class="scrollbar-container export-builder" v-if="!loading && !exporting && subPage === 'export_overview'">
      <labelc type="header title" v-bind:text="overviewTitleText" style="font-size: 40px" />

      <table class="table">
        <tr>
          <th>Gestartet von</th>
          <th>Export gestartet</th>
          <th>Export gesendet</th>
          <th>Export herunterladen</th>
        </tr>
        <tr v-for="exportJob in exportJobs" v-bind:key="exportJob.id">
          <td>{{ exportJob.authuserName }}</td>
          <td>{{ exportJob.jobStarted }}</td>
          <td>{{ exportJob.jobEnded }}</td>
          <td>
            <i v-if="exportJob.exportCsv" class="fas fa-file-download" v-on:click="downloadCSVFromExportJob(exportJob.exportCsv, exportJob.jobStartedISO)"></i>
          </td>
        </tr>
      </table>

    </div>
    <div v-if="loading" class="loading-md bg" style="z-index: 20">
      <i class="fas fa-spinner"></i>
      <br />
      <div v-if="!$helpers.isEmpty(loadingText)" class="loading-text">
        {{ loadingText }}
      </div>
    </div>

    <div v-if="exporting" class="loading-md bg" style="z-index: 20">
      <i class="fas fa-spinner"></i>
      <br />
      <div v-if="!$helpers.isEmpty(loadingText)" class="loading-text">Bitte warten. Der Export wird generiert.</div>
    </div>

    <b-modal ref="modal-pdfpreview" @close="closePdfPreview()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Report erstellt</div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="my-1" style="text-align: left;">
          <div>
            <span>Report wurde erstellt und steht zum Öffnen oder Herunterladen bereit.</span>
          </div>
        </b-row>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closePdfPreview()">
          <div class="label">
            Schließen
          </div>
        </div>
        <div class="button ml right" @click="openPreviewedPdf()">
          <div class="label">
            In neuem Tab öffnen
          </div>
        </div>
        <div class="button ml right" @click="savePreviewedPdf()">
          <div class="label">
            Speichern
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import 'jspdf-autotable';
import Dropdownsearch from "@/components/uicomponents/dropdownsearch.vue";
export default {
  name: "ExportBuilder",
  components: {Dropdownsearch},
  data() {
    return {
      titleText: "CSV Export",
      availableHeaders: {
        "Betriebs ID": true,
        "Mitarbeiter ID": true,
        "Mitarbeiter Namein": true,
        Lohnart: true,
        "Ausbezahlte Arbeitszeit": false,
        "Inhalt Bezeichnung": false,
      },

      somethingHasChangedWhatAffectsExportTable: false,
      buildingExportTable: false,

      isDragging: false,
      panelDragged: null,
      headerReplace: {},
      selectedStores: {},
      loadingText: "Daten werden geladen...",
      hiddenStates: {
        exportsettings: false,
        employmentModels: false,
        wageTypes: false,
        employees: false,
        preview: false,
      },
      orderStates: {
        exportsettings: 0,
        employmentModels: 1,
        wageTypes: 2,
        employees: 3,
        preview: 4,
      },
      collapseStates: {
        exportsettings: false,
        employmentModels: false,
        wageTypes: false,
        employees: true,
        preview: false,
      },
      employeeNameFilter: "",

      missingPersonalIds: [],

      loading: true,
      exporting: false,
      saves: false,
      subPage: 'export',
      prevPage: '',

      loadStores: true,
      storesMap: [],
      stores: [],
      storeSettingWeekdaysMap: {},
      employeeMap: {},
      selectedStoreIds: [],
      hiddenStores: 0,

      employeeMdids: {},
      mdEmployeeContractMap: {},
      mdContractArray: [],

      prevMonthlyStatements: [],
      monthlyStatements: [],
      showMonthlyStatement: false,

      exportTable: [],
      exportPreviewVisible: false,

      monthlyStatementPerEmployee: {},
      previousMonthlyStatementPerEmployee: {},
      preferencePerEmployee: {},
      duplicateEmployees: [],

      prevMonthStatementResponses: [],
      statementResponses: [],
      employeeWorkDaysCount: [],

      selectedDate: this.$helpers.getMomentTZ().clone().subtract(1, "months"),
      timeframeStart: this.$helpers.getMomentTZ().clone().subtract(1, "months").startOf("month"),
      timeframeEnd: this.$helpers.getMomentTZ().clone().subtract(1, "months").endOf("month"),

      modalPdf: null,
      modalPdfDoc: null,
      modalPdfFilename: '',

      monthNames: {
        0: "Jänner",
        1: "Februar",
        2: "März",
        3: "April",
        4: "Mai",
        5: "Juni",
        6: "Juli",
        7: "August",
        8: "September",
        9: "Oktober",
        10: "November",
        11: "Dezember",
      },

      balanceItems: [],
      balanceAddtionItems: [
        {
          "key": 0,
          "label": "Kein Zuschlag"
        },
        {
          "key": 1,
          "label": "25% Zuschlag"
        },
        {
          "key": 2,
          "label": "50% Zuschlag"
        },
        {
          "key": 3,
          "label": "75% Zuschlag"
        },
        {
          "key": 4,
          "label": "100% Zuschlag"
        },
      ],
      ppValuesAdditionalItems: [
        {
          "key": 0,
          "label": "Minuten"
        },
        {
          "key": 1,
          "label": "Stunden"
        },
        {
          "key": 2,
          "label": "Industrieminuten"
        }
      ],
      monthItems: [
        {
          "key": "current",
          "label": "Aktueller Monat"
        },
        {
          "key": "previous",
          "label": "Voriger Monat"
        },
        {
          "key": "next",
          "label": "Nächster Monat"
        },
      ],
      paidWorkTimeAdditionalItems: [
        {
          "key": 0,
          "label": "Null Werte exportieren"
        },
        {
          "key": 1,
          "label": "Keine Null Werte exportieren"
        },
      ],
      headerAdditionalItems: [
        {
          "key": 0,
          "label": "Spaltenbezeichnungen nicht andrucken"
        },
        {
          "key": 1,
          "label": "Spaltenbezeichnungen andrucken"
        },
      ],
      exportDateItems: [
        {
          "key": 0,
          "label": "Abwesenheiten neu anlegen"
        },
        {
          "key": 1,
          "label": "Abwesenheiten zusammenhängen"
        },
      ],
      dailyPayoutValueAdditionalItems: [
        {
          "key": 0,
          "label": "Wert in Stunden"
        },
        {
          "key": 1,
          "label": "Wert in Tagen"
        },
      ],
      dailyPayoutHoursValueAdditionalItems: [
        {
          "key": 0,
          "label": "Wert in Stunden"
        },
        {
          "key": 1,
          "label": "Wert in Tagen"
        },
      ],

      availableFieldsMonthly: {
        externalCompanyId: {
          key: "externalCompanyId",
          name: "Betrieb ID",
          newName: "Firma",
          checked: true,
        },
        externalEmployeeId: {
          key: "externalEmployeeId",
          name: "Mitarbeiter ID",
          newName: "Mitarbeiternummer",
          checked: true,
        },
        month: {
          key: "month",
          name: "Monat",
          newName: "Monat",
          checked: true,
        },
        lohnart: {
          key: "lohnart",
          name: "Lohnart",
          newName: "Lohnart",
          checked: true,
        },
        paidWorkTime: {
          key: "paidWorkTime",
          name: "Ausbezahlter Wert",
          newName: "Ausbezahlter Wert",
          checked: true,
        },
        contentName: {
          key: "contentName",
          name: "Inhalt Bezeichnung",
          newName: "Inhalt",
          checked: true,
        },
        employeeName: {
          key: "employeeName",
          name: "Mitarbeiter Name",
          newName: "Name",
          checked: true,
        },
      },
      availableFieldsMonthlyAdditional: {
        headerAdditional: {
          key: "headerAdditional",
          name: "Listenaufbau",
          item: {
            "key": 0,
            "label": "Spaltenbezeichnungen nicht andrucken"
          },
          checked: true,
        },
        monthAdditional: {
          key: "monthAdditional",
          name: "Monat",
          item: {
            "key": "current",
            "label": "Aktueller Monat"
          },
          checked: true,
        },
        paidWorkTimeAdditional: {
          key: "paidWorkTimeAdditional",
          name: "Ausbezahlter Wert",
          item: {
            "key": 0,
            "label": "Null Werte exportieren"
          },
          checked: true,
        },
      },
      availableFieldsDaily: {
        dailyExternalCompanyId: {
          key: "dailyExternalCompanyId",
          name: "Betrieb ID",
          newName: "Firma",
          checked: true,
        },
        dailyExternalEmployeeId: {
          key: "dailyExternalEmployeeId",
          name: "Mitarbeiter ID",
          newName: "Personalnummer",
          checked: true,
        },
        dailyLohnart: {
          key: "dailyLohnart",
          name: "Lohnart",
          newName: "Art",
          checked: true,
        },
        dailyFrom: {
          key: "dailyFrom",
          name: "Startdatum",
          newName: "von",
          checked: true,
        },
        dailyTo: {
          key: "dailyTo",
          name: "Endedatum",
          newName: "bis",
          checked: true,
        },
        dailyPayoutValue: {
          key: "dailyPayoutValue",
          name: "Wert",
          newName: "Verwaltung",
          checked: true,
        },
        dailyPayoutHoursValue: {
          key: "dailyPayoutHoursValue",
          name: "Wert Bezahlt",
          newName: "Bezahlt",
          checked: false,
        },
        dailyProcessingFlag: {
          key: "dailyProcessingFlag",
          name: "Verarbeitungs-KZ",
          newName: "Verarbeitungs-KZ",
          checked: true,
        },
        dailyContentName: {
          key: "dailyContentName",
          name: "Inhalt Bezeichnung",
          newName: "Inhalt",
          checked: true,
        },
        dailyEmployeeName: {
          key: "dailyEmployeeName",
          name: "Mitarbeiter Name",
          newName: "Name",
          checked: true,
        },
      },
      availableFieldsDailyAdditional: {
        headerAdditional: {
          key: "headerAdditional",
          name: "Listenaufbau",
          item: {
            "key": 0,
            "label": "Spaltenbezeichnungen nicht andrucken"
          },
          checked: true,
        },
        dailyPayoutValueAdditional: {
          key: "dailyPayoutValueAdditional",
          name: "Wert",
          item: {
            "key": 0,
            "label": "Wert in Stunden"
          },
          checked: true,
        },
        dailyPayoutHoursValueAdditional: {
          key: "dailyPayoutHoursValueAdditional",
          name: "Wert Bezahlt",
          item: {
            "key": 0,
            "label": "Wert in Stunden"
          },
          checked: true,
        },
        dailyProcessingFlagAdditional: {
          key: "dailyProcessingFlagAdditional",
          name: "Verarbeitungs-KZ",
          item: {
            "key": 0,
            "label": "Abwesenheiten neu anlegen"
          },
          checked: true,
        },
      },
      availableFieldsPersonnelProvisions: {
        externalCompanyId: {
          key: "ppExternalCompanyId",
          name: "Betrieb ID",
          newName: "Firma",
          checked: true,
        },
        externalEmployeeId: {
          key: "ppExternalEmployeeId",
          name: "Mitarbeiter ID",
          newName: "Mitarbeiternummer",
          checked: true,
        },
        employeeName: {
          key: "ppEmployeeName",
          name: "Mitarbeiter Name",
          newName: "Name",
          checked: false,
        },
        balance1: {
          key: "balance1",
          name: "Lohnart 1",
          newName: "Mehrstunden Saldo",
          item: {
            "key": 36,
            "label": "Mehrstunden Saldo"
          },
          checked: true,
        },
        balance2: {
          key: "balance2",
          name: "Lohnart 2",
          newName: "Überstunden Saldo",
          item: {
            "key": 29,
            "label": "Überstunden Saldo"
          },
          checked: true,
        },
        balance3: {
          key: "balance3",
          name: "Lohnart 3",
          newName: "Feiertags Saldo",
          item: {
            "key": 1,
            "label": "Feiertags Saldo"
          },
          checked: true,
        },
        balance4: {
          key: "balance4",
          name: "Lohnart 4",
          newName: "Rest Urlaub im Kalenderjahr",
          item: {
            "key": 0,
            "label": "Rest Urlaub im Kalenderjahr"
          },
          checked: false,
        },
        balance5: {
          key: "balance5",
          name: "Lohnart 5",
          newName: "Rest Urlaub im Kalenderjahr",
          item: {
            "key": 0,
            "label": "Rest Urlaub im Kalenderjahr"
          },
          checked: false,
        },
      },
      availableFieldsPersonnelProvisionsAdditional: {
        headerAdditional: {
          key: "ppHeaderAdditional",
          name: "Listenaufbau",
          item: {
            "key": 0,
            "label": "Spaltenbezeichnungen nicht andrucken"
          },
          checked: true,
        },
        valuesAdditional: {
          key: "ppValuesAdditional",
          name: "Werte in",
          item: {
            "key": 2,
            "label": "Industrieminuten"
          },
          checked: true,
        },
        balance1Additional: {
          key: "balance1Additional",
          name: "Lohnart 1",
          item: {
            "key": 1,
            "label": "25% Zuschlag"
          },
          checked: true,
        },
        balance2Additional: {
          key: "balance2Additional",
          name: "Lohnart 2",
          item: {
            "key": 2,
            "label": "50% Zuschlag"
          },
          checked: true,
        },
        balance3Additional: {
          key: "balance3Additional",
          name: "Lohnart 3",
          item: {
            "key": 0,
            "label": "Kein Zuschlag"
          },
          checked: false,
        },
        balance4Additional: {
          key: "balance4Additional",
          name: "Lohnart 4",
          item: {
            "key": 0,
            "label": "Kein Zuschlag"
          },
          checked: false,
        },
        balance5Additional: {
          key: "balance5Additional",
          name: "Lohnart 5",
          item: {
            "key": 0,
            "label": "Kein Zuschlag"
          },
          checked: false,
        },
      },
      content: [],
      employmentModelArray: [],
      currentCSV: "",
      bmdId: 0,
      softwareId: -1,
      financialAccountingGroups: null,

      settingsUpdated: false,
      baseSettingsBackup: {
        enableExportViaTransferService: false,
        enablePeriodicExport: false,
        enableMonthlyClosingExport: false,
        periodicExportTimes: [],
        monthlyClosingExportSettings: [],
      },
      baseSettings: {
        enableExportViaTransferService: false,
        enablePeriodicExport: false,
        enableMonthlyClosingExport: false,
        periodicExportTimes: [],
        monthlyClosingExportSettings: [],
      },
      selectedSettingsId: 0,
      sExportSettingsBackup : [
        // {name: "Standard", id: 0, selectedStores: [], availableFields: [], availableFieldsAdditional: [], selectedWageTypes: []},
        // {name: "Export 2", id: 2, selectedStores: [], availableFields: [], availableFieldsAdditional: [], selectedWageTypes: []},
        // {name: "Urlaube monatlich", id: 3, selectedStores: [], availableFields: [], availableFieldsAdditional: [], selectedWageTypes: []}
      ],
      sExportSettings : [
        // {name: "Standard", id: 0, selectedStores: [], availableFields: [], availableFieldsAdditional: [], selectedWageTypes: []},
        // {name: "Export 2", id: 2, selectedStores: [], availableFields: [], availableFieldsAdditional: [], selectedWageTypes: []},
        // {name: "Urlaube monatlich", id: 3, selectedStores: [], availableFields: [], availableFieldsAdditional: [], selectedWageTypes: []}
      ],

      periodicExportTimeReportMonth: [
        {
          value: 0,
          label: "Voriger Monat",
        },
        {
          value: 1,
          label: "Aktueller Monat",
        }
      ],
      periodicExportTimeTypes: [
        {
          value: 0,
          label: "Täglich",
        },
        {
          value: 1,
          label: "Wöchentlich",
        },
        {
          value: 2,
          label: "Monatlich",
        }
      ],

      exportJobs: [],
    };
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh(false, 1);
    },
    globalFilter: function (val, oldVal) {
      this.globalFilterChanged();
    },
    baseSettings: {
      handler (val, oldVal) {
        val.periodicExportTimes.forEach(time => {
          let hours = parseInt(time.hours);
          if (hours > 23) {
            time.hours = 23;
          } else if (hours < 10) {
            time.hours = "0" + hours;
          } else {
            time.hours = hours;
          }

          let minutes = parseInt(time.minutes);
          if (minutes > 59) {
            time.minutes = 59;
          } else if (minutes < 10) {
            time.minutes = "0" + minutes;
          } else {
            time.minutes = minutes;
          }
        });
      },
      deep: true,
    },
  },
  created() {
    let view = this;
  },
  mounted() {
    let view = this;
    this.LoadFilterDropdown();
    this.titleText = ["individual-wagetypes"].includes(view.$parent.mode) ? "CSV Export - Individuelle Lohnarten"
      : this.$parent.mode == 'monthly' ? "CSV Export - Monatssummen"
      : this.$parent.mode == 'daily' ? "CSV Export - Abwesenheiten"
      : "CSV Export - Rückstellungen";
    this.settingsTitleText = ["individual-wagetypes"].includes(view.$parent.mode) ? "CSV Export Einstellungen - Individuelle Lohnarten"
      : this.$parent.mode == 'monthly' ? "CSV Export Einstellungen - Monatssummen"
      : this.$parent.mode == 'daily' ? "CSV Export Einstellungen - Abwesenheiten"
      : "CSV Export Einstellungen - Rückstellungen";
    this.overviewTitleText = ["individual-wagetypes"].includes(view.$parent.mode) ? "Export Übersicht - Individuelle Lohnarten"
      : this.$parent.mode == 'monthly' ? "Export Übersicht - Monatssummen"
      : this.$parent.mode == 'daily' ? "Export Übersicht - Abwesenheiten"
      : "Export Übersicht - Rückstellungen";

    view.$helpers.GetExportSelectedDate().then((data) => {
      if(data.currentDate){
        const isLessThanOneHourAgo = view.$helpers.getMomentTZ().diff(data.currentDate, 'hours') < 3;
        if (isLessThanOneHourAgo) {
          view.selectedDate = view.$helpers.getMomentFromString(data.date);
          view.timeframeStart = view.selectedDate.clone().startOf("month");
          view.timeframeEnd = view.selectedDate.clone().endOf("month");
          view.$forceUpdate();
        }
      }
    });

    view.$helpers.GetExportSelectedColumns().then((data) => {
      for (let i in view.availableFields) {
        if (data[view.availableFields[i].key]) {
          view.availableFields[i].checked = data[view.availableFields[i].key].checked;
          if (view.$parent.mode === 'personnelProvisions') { // special case for personnel provisions
            view.availableFields[i].item = data[view.availableFields[i].key].item;
            view.availableFields[i].newName = data[view.availableFields[i].key].item ?
              data[view.availableFields[i].key].item.label
              : data[view.availableFields[i].key].newName;
          } else {
            view.availableFields[i].newName = data[view.availableFields[i].key].newName;
          }
        }

      }
      for (let i in view.availableFieldsAdditional) {
        if (data[view.availableFieldsAdditional[i].key]) {
          view.availableFieldsAdditional[i].checked = data[view.availableFieldsAdditional[i].key].checked;
          view.availableFieldsAdditional[i].item = data[view.availableFieldsAdditional[i].key].item;
        }
      }
    });

    this.refresh(false, 3);
    let localOrder = localStorage.getItem("localOrderExportBuilderPanels");
    if (localOrder) {
      this.orderStates = JSON.parse(localOrder);
    }
  },
  computed: {
    ...mapGetters(["companyId", "isSuperUser", "loggedIn", "userInfo", "activeStoreUsersMap", "companyArray", "storeArray", "globalFilter"]),
    contentMain() {
      let data = [];
      for (let i in this.content) {
        if (this.content[i].isMain) {
          data.push(this.content[i]);
        }
      }
      return data;
    },
    contentSecondary() {
      let data = [];
      for (let i in this.content) {
        if (!this.content[i].isMain) {
          data.push(this.content[i]);
        }
      }
      return data;
    },

    sContentMain() {
      let data = [];
      let currentSetting = this.sExportSettings.find(e => e.id === this.selectedSettingsId);
      for (let i in this.content) {
        if (this.content[i].isMain) {
          let currentEntry = JSON.parse(JSON.stringify(this.content[i]));
          currentEntry.checked = currentSetting ? currentSetting.selectedWageTypes.includes(currentEntry.id) : false;
          data.push(currentEntry);
        }
      }
      return data;
    },
    sContentSecondary() {
      let data = [];
      let currentSetting = this.sExportSettings.find(e => e.id === this.selectedSettingsId);
      for (let i in this.content) {
        if (!this.content[i].isMain) {
          let currentEntry = JSON.parse(JSON.stringify(this.content[i]));
          currentEntry.checked = currentSetting ? currentSetting.selectedWageTypes.includes(currentEntry.id) : false;
          data.push(currentEntry);
        }
      }
      return data;
    },

    employmentModels() {
      return this.employmentModelArray;
    },

    availableFields() {
      let view = this;
      if (['monthly', 'individual-wagetypes'].includes(view.$parent.mode)) {
        return view.availableFieldsMonthly;
      } else if (view.$parent.mode === 'daily') {
        return view.availableFieldsDaily;
      } else if (view.$parent.mode === 'personnelProvisions') {
        return view.availableFieldsPersonnelProvisions;
      }
      return {};
    },
    availableFieldsAdditional() {
      let view = this;

      if (['monthly', 'individual-wagetypes'].includes(view.$parent.mode)) {
        return view.availableFieldsMonthlyAdditional;
      } else if (view.$parent.mode === 'daily') {
        return view.availableFieldsDailyAdditional;
      } else if (view.$parent.mode === 'personnelProvisions') {
        return view.availableFieldsPersonnelProvisionsAdditional;
      }
    },

    dateDisplay: function () {
      let view = this;
      return view.monthNames[view.timeframeStart.months()] + " " + view.timeframeStart.format("YYYY");
    },
    headerButtons() {
      let view = this;

      let state = {
        buttons: [],
      };


      state.buttons.push({
        type: "block border-r",
        icon: "fa-refresh",
        disabled: this.loading || view.settingsUpdated,
        click: () => {
          view.refresh(true, 4);
        },
        tooltip: "Daten neu laden",
      });

      // TODO: add subPage for showing the exports

      if (view.subPage === 'export') {

        state.buttons.push({
          type: "block border-r",
          icon: "fa-cog",
          disabled: this.loading,
          click: () => {
            view.subPage = 'settings';
          },
          tooltip: "Export Einstellungen",
        });
        state.buttons.push({
          type: "block border-r",
          icon: "fa-magnifying-glass",
          show: view.baseSettings.enableExportViaTransferService,
          disabled: this.loading || view.settingsUpdated,
          click: () => {
            view.prevPage = 'export';
            view.subPage = 'export_overview';
          },
          tooltip: "Exporte ansehen",
          title: "Exporte ansehen",
        });

        if (view.somethingHasChangedWhatAffectsExportTable && !view.buildingExportTable) {
          state.buttons.push({
            type: "block",
            icon: "fa-table-cells",
            disabled: this.loading,
            click: () => {
              view.buildExportTable();
            },
            tooltip: "Tabelle neu generieren ",
          });
        }

        if (!view.somethingHasChangedWhatAffectsExportTable) {
          state.buttons.push({
            type: "block",
            icon: "fa-file-csv",
            disabled: this.loading,
            click: () => {
              view.doCsvDownload();
            },
            tooltip: "CSV Downloaden",
          });
          state.buttons.push({
            type: "block border-l",
            icon: "fa-file-export",
            show: view.baseSettings.enableExportViaTransferService,
            disabled: this.loading,
            click: () => {
              view.triggerCsvExportWithFrontendSettings();
            },
            tooltip: "Export mit aktuellen Einstellungen anstoßen",
          });
        }


      } else if (view.subPage === 'settings') {

        state.buttons.push({
          type: "block",
          icon: "fa-chevron-left",
          disabled: this.loading || view.settingsUpdated,
          click: () => {
            view.subPage = 'export';
          },
          tooltip: "Zurück zum Export",
        });
        state.buttons.push({
          type: "block border-l",
          icon: "fa-magnifying-glass",
          disabled: this.loading || view.settingsUpdated,
          click: () => {
            view.prevPage = 'settings';
            view.subPage = 'export_overview';
          },
          tooltip: "Exporte ansehen",
          title: "Exporte ansehen",
        });
        state.buttons.push({
          type: "block border-l",
          icon: "fa-file-export",
          show: view.baseSettings.enableExportViaTransferService,
          disabled: this.loading || view.settingsUpdated,
          click: () => {
            view.openCsvExportWithSettingsPopup();
          },
          tooltip: "Export",
        });
        state.buttons.push({
          type: "block green",
          icon: "fa-save",
          show: view.settingsUpdated,
          click: () => {
            if (view.baseSettings.enableMonthlyClosingExport) {
              let error = false;
              view.baseSettings.monthlyClosingExportSettings.forEach((setting) => {
                let exportSetting = view.sExportSettings.find((e) => e.id === setting.exportId);
                if (exportSetting && exportSetting.selectedStores.length > 1) {
                  error = true;
                }
              });
              if (error) {
                view.$helpers.error("Fehler", "Es können keine Einstellungen für mehrere Kostenstellen bei Monatsabschluss Exports gespeichert werden.");
                return;
              }
            }

            view.baseSettings.periodicExportTimes.forEach((time) => { time.editing = false; });
            let successmessage = {
              title: "Erfolg",
              message: "Einstellungen erfolgreich gespeichert."
            };
            let errormessage = {
              title: "Fehler",
              message: "Einstellungen konnten nicht gespeichert werden."
            };
            view.putExportSettings(view.$parent.mode, successmessage, errormessage)
            .then((response) => {
              view.baseSettingsBackup = JSON.parse(JSON.stringify(view.baseSettings));
              view.sExportSettingsBackup = JSON.parse(JSON.stringify(view.sExportSettings));
              view.settingsUpdated = false;
            })
            .catch((e) => {
              console.log(e);
            });
          },
          tooltip: "Speichern",
        });
        state.buttons.push({
          type: "block red",
          icon: "fa-minus-circle",
          show: view.settingsUpdated,
          click: () => {
            view.baseSettings = JSON.parse(JSON.stringify(view.baseSettingsBackup));
            view.sExportSettings = JSON.parse(JSON.stringify(view.sExportSettingsBackup));
            view.selectedSettingsId = view.sExportSettings.length > 0 ? view.sExportSettings[0].id : 0;
            view.settingsUpdated = false;
          },
          tooltip: "Änderungen verwerfen",
        });

      } else if (view.subPage === 'export_overview') {

        state.buttons.push({
          type: "block",
          icon: "fa-chevron-left",
          disabled: this.loading,
          click: () => {
            view.subPage = view.prevPage;
          },
          tooltip: "Zurück",
        });

      } else {

      }

      return state;
    },
    sideButtons() {
      let view = this;

      let state = {
        buttons: [],
      };
      if (view.subPage === 'export') {
        state.buttons.push({
          type: "block border-r",
          icon: "fa-eye",
          tooltip: "Anzeige Filter",
          click: ($event) => {
            view.OpenFilterDropdown($event);
          },
        });
      }

      if ((((["monthly", "individual-wagetypes"].includes(view.$parent.mode) || view.$parent.mode == "daily") && view.softwareId == 0) || view.subPage == "export")) {
        state.buttons.push({
          type: "block",
          icon: "fa-bars",
          tooltip: "Zusätzliche Downloads",
          click: ($event) => {
            view.OpenMenuDetails($event, this.burgerMenuState);
          },
        });
      }

      return state;
    },
    // export page
    settingsCompanyState() {
      let view = this;

      let customStores = [];
      view.hiddenStores = 0;
      view.stores.forEach(function (store) {
        let pushed = false;
        if (view.globalFilter && view.globalFilter.stores && view.globalFilter.companyId == view.$store.state.companyId) {
          for (let i in view.globalFilter.stores) {
            if (view.globalFilter.stores[i].id == store.id && view.globalFilter.stores[i].selected) {
              customStores.push(store);
              view.storesMap[store.id].selected = true;
              pushed = true;
            }
          }
        }
        if (!pushed) {
          view.storesMap[store.id].selected = false;
          view.hiddenStores++;
        }
      });

      let state = this.$helpers.GetDataTableStateFromKeyLabelObject(
        {
          storeId: "Kostenstellen",
          employeeFinishedState: "Monatsabschlüsse erledigt / nicht erledigt",
        },
        customStores
      );

      state.headerMap.storeId.width = "40%";
      state.headerMap.storeId.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "buttonc",
            label: data.name,
            type: "occupy-container",
            class: `  ${view.selectedStores[data.id] ? "selected" : ""}`,
            tooltip: "Auswählen / Abwählen",
            customEvents: {
              click: () => {
                view.$set(view.selectedStores, data.id, !view.selectedStores[data.id]);
                view.storesMap[data.id].selected = view.selectedStores[data.id];
                view.toggleStoreLocalStorage(data.id, view.selectedStores[data.id]);
                view.somethingHasChangedWhatAffectsExportTable = true;
                view.checkDuplicateEmployees();
                view.$forceUpdate();
              },
            },
            styleText: { "text-align": "left", width: "100%" },
          };
        },
      ];

      state.headerMap.employeeFinishedState.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "buttonc",
            label: "Monatsabschluss",
            type: "occupy-container",
            style: "pointer-events:none; box-shadow: inset  10px 0 0 -5px var(--bg)",
          };
        },
        (data, entry) => {
          return {
            componentType: "buttonc",
            icon: !view.monthlyStatements[data.id].loading && view.monthlyStatements[data.id].confirmed ? "fa-check" : "fa-times",
            type: "icon-overlay",
            style: "pointer-events:none;position: absolute; top: 0, left: initial !important; right: 120px !important; bottom: 0; margin: auto; transform: scale(.8)",
            styleIcon: {
              color: !view.monthlyStatements[data.id].loading && view.monthlyStatements[data.id].confirmed ? "var(--md)" : "var(--ml)",
            },
          };
        },
      ];
      Object.values(state.headerMap).forEach((h) => (h.stylesHeader = { "justify-content": "start" }));
      state.headerMap.employeeFinishedState.stylesHeader.paddingLeft = "5px";
      state.compact = true;
      return state;
    },
    settingsHeaderState() {
      let view = this;
      let state = this.$helpers.GetDataTableStateFromKeyLabelObject(
        {
          listStructure: "Listenaufbau",
          columns: "Individuelle Spaltenbezeichnungen",
        },
        Object.values(this.availableFields)
      );

      state.headerMap.listStructure.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "buttonc",
            label: data.name,
            type: "occupy-container",
            tooltip: "Auswählen / Abwählen",
            class: `  ${data.checked ? "selected" : ""}`,
            customEvents: {
              click: () => {
                data.checked = !data.checked;
                view.saveAvailableFieldsLS();
                view.somethingHasChangedWhatAffectsExportTable = true;
                view.$forceUpdate();
              },
            },
            style: "height: calc(100% - 5px) !important; width: calc(100% - 8px) !important;",
            styleLabel: { "text-align": "left", width: "100%" },
          };
        },
      ];
      state.headerMap.columns.componentStateBuilders = [
        (data, entry) => {

          if (data.key.startsWith("balance")) {
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.balanceItems,
                selectedItem: view.availableFields[data.key].item,
                onceSelected: function ($event, item) {
                  view.availableFields[data.key].item = item;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
                styleLight: true,
                style: "",
              },
            };
          } else {
            return {
              componentType: "inputc",
              inputProp: data.newName,
              placeholder: data.newName,
              style: "text-align: left; box-shadow: inset 0 0 0 1px var(--contrast-3); height: calc(100% - 5px); width: calc(100% - 8px); top: 0px",
              customEvents: {
                Update: (val) => {
                  data.newName = val;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
              },
            };
          }
        },
      ];
      Object.values(state.headerMap).forEach((h) => (h.stylesHeader = { "justify-content": "start" }));
      state.compact = true;
      return state;
    },
    settingsMonthlyAdditionalState() {
      let view = this;
      let state = this.$helpers.GetDataTableStateFromKeyLabelObject(
        {
          header: "Listenelemente",
          columns: "Zusätzliche Einstellungen",
        },
        Object.values(this.availableFieldsAdditional)
      );

      state.headerMap.header.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "buttonc",
            label: data.name,
            type: "occupy-container",
            tooltip: "Auswählen / Abwählen",
            class: `  ${data.checked ? "selected" : ""}`,
            customEvents: {
              click: () => {
                data.checked = !data.checked;
                view.saveAvailableFieldsLS();
                view.somethingHasChangedWhatAffectsExportTable = true;
                view.$forceUpdate();
              },
            },
            style: "height: calc(100% - 5px) !important; width: calc(100% - 8px) !important;",
            styleLabel: { "text-align": "left", width: "100%" },
          };
        },
      ];
      state.headerMap.columns.componentStateBuilders = [
        (data, entry) => {

          if (data.key === "monthAdditional") {
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.monthItems,
                selectedItem: view.availableFieldsAdditional.monthAdditional.item,
                onceSelected: function ($event, item) {
                  view.availableFieldsAdditional.monthAdditional.item = item;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
                styleLight: true,
                style: "",
              },
            };
          } else if (data.key === "paidWorkTimeAdditional") {
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.paidWorkTimeAdditionalItems,
                selectedItem: view.availableFieldsAdditional.paidWorkTimeAdditional.item,
                onceSelected: function ($event, item) {
                  view.availableFieldsAdditional.paidWorkTimeAdditional.item = item;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
                styleLight: true,
                style: "",
              },
            };
          } else if (data.key === "headerAdditional") {
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.headerAdditionalItems,
                selectedItem: view.availableFieldsAdditional.headerAdditional.item,
                onceSelected: function ($event, item) {
                  view.availableFieldsAdditional.headerAdditional.item = item;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
                styleLight: true,
                style: "",
              },
            };
          } else {
            return {
              componentType: "inputc",
              inputProp: data.newName,
              placeholder: data.newName,
              style: "text-align: left; box-shadow: inset 0 0 0 1px var(--contrast-3); height: calc(100% - 5px); width: calc(100% - 8px); top: 0px",
              customEvents: {
                Update: (val) => {
                  data.newName = val;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
              },
            };
          }
        },
      ];
      Object.values(state.headerMap).forEach((h) => (h.stylesHeader = { "justify-content": "start" }));
      state.compact = true;
      return state;
    },
    settingsDailyAdditionalState() {
      let view = this;
      let state = this.$helpers.GetDataTableStateFromKeyLabelObject(
        {
          header: "Listenelemente",
          columns: "Zusätzliche Einstellungen",
        },
        Object.values(this.availableFieldsAdditional)
      );

      state.headerMap.header.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "buttonc",
            label: data.name,
            type: "occupy-container",
            tooltip: "Auswählen / Abwählen",
            class: `  ${data.checked ? "selected" : ""}`,
            customEvents: {
              click: () => {
                data.checked = !data.checked;
                view.saveAvailableFieldsLS();
                view.somethingHasChangedWhatAffectsExportTable = true;
                view.$forceUpdate();
              },
            },
            style: "height: calc(100% - 5px) !important; width: calc(100% - 8px) !important;",
            styleLabel: { "text-align": "left", width: "100%" },
          };
        },
      ];
      state.headerMap.columns.componentStateBuilders = [
        (data, entry) => {

          if (data.key === "dailyProcessingFlagAdditional") {
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.exportDateItems,
                selectedItem: view.availableFieldsAdditional.dailyProcessingFlagAdditional.item,
                onceSelected: function ($event, item) {
                  view.availableFieldsAdditional.dailyProcessingFlagAdditional.item = item;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
                styleLight: true,
                style: "",
              },
            };
          } else if (data.key === "headerAdditional") {
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.headerAdditionalItems,
                selectedItem: view.availableFieldsAdditional.headerAdditional.item,
                onceSelected: function ($event, item) {
                  view.availableFieldsAdditional.headerAdditional.item = item;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
                styleLight: true,
                style: ""
              },
            };
          } else if (data.key === "dailyPayoutValueAdditional") {
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.dailyPayoutValueAdditionalItems,
                selectedItem: view.availableFieldsAdditional.dailyPayoutValueAdditional.item,
                onceSelected: function ($event, item) {
                  view.availableFieldsAdditional.dailyPayoutValueAdditional.item = item;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
                styleLight: true,
                style: ""
              },
            };
          } else if (data.key === "dailyPayoutHoursValueAdditional") {
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.dailyPayoutHoursValueAdditionalItems,
                selectedItem: view.availableFieldsAdditional.dailyPayoutHoursValueAdditional.item,
                onceSelected: function ($event, item) {
                  view.availableFieldsAdditional.dailyPayoutHoursValueAdditional.item = item;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
                styleLight: true,
                style: ""
              },
            };
          } else {
            return {
              componentType: "inputc",
              inputProp: data.newName,
              placeholder: data.newName,
              style: "text-align: left; box-shadow: inset 0 0 0 1px var(--contrast-3); height: calc(100% - 5px); width: calc(100% - 8px); top: 0px",
              customEvents: {
                Update: (val) => {
                  data.newName = val;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
              },
            };
          }
        },
      ];
      Object.values(state.headerMap).forEach((h) => (h.stylesHeader = { "justify-content": "start" }));
      state.compact = true;
      return state;
    },
    settingsPersonnelProvisionsAdditionalState() {
      let view = this;
      let state = this.$helpers.GetDataTableStateFromKeyLabelObject(
        {
          header: "Listenelemente",
          columns: "Zusätzliche Einstellungen",
        },
        Object.values(this.availableFieldsAdditional)
      );

      state.headerMap.header.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "buttonc",
            label: data.name,
            type: "occupy-container",
            tooltip: "Auswählen / Abwählen",
            class: `  ${data.checked ? "selected" : ""}`,
            customEvents: {
              click: () => {
                data.checked = !data.checked;
                view.saveAvailableFieldsLS();
                view.somethingHasChangedWhatAffectsExportTable = true;
                view.$forceUpdate();
              },
            },
            style: "height: calc(100% - 5px) !important; width: calc(100% - 8px) !important;",
            styleLabel: { "text-align": "left", width: "100%" },
          };
        },
      ];
      state.headerMap.columns.componentStateBuilders = [
        (data, entry) => {

          if (data.key === "ppHeaderAdditional") {
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.headerAdditionalItems,
                selectedItem: view.availableFieldsAdditional.headerAdditional.item,
                onceSelected: function ($event, item) {
                  view.availableFieldsAdditional.headerAdditional.item = item;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
                styleLight: true,
                style: "",
              },
            };
          } else if (data.key === "ppValuesAdditional") {
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.ppValuesAdditionalItems,
                selectedItem: view.availableFieldsAdditional.valuesAdditional.item,
                onceSelected: function ($event, item) {
                  view.availableFieldsAdditional.valuesAdditional.item = item;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
                styleLight: true,
                style: "",
              },
            };
          } else if (data.key.startsWith("balance")) {
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.balanceAddtionItems,
                selectedItem: view.availableFieldsAdditional[data.key].item,
                onceSelected: function ($event, item) {
                  view.availableFieldsAdditional[data.key].item = item;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
                styleLight: true,
                style: "",
              },
            };
          } else {
            return {
              componentType: "inputc",
              inputProp: data.newName,
              placeholder: data.newName,
              style: "text-align: left; box-shadow: inset 0 0 0 1px var(--contrast-3); height: calc(100% - 5px); width: calc(100% - 8px); top: 0px",
              customEvents: {
                Update: (val) => {
                  data.newName = val;
                  view.saveAvailableFieldsLS();
                  view.somethingHasChangedWhatAffectsExportTable = true;
                  view.$forceUpdate();
                },
              },
            };
          }
        },
      ];
      Object.values(state.headerMap).forEach((h) => (h.stylesHeader = { "justify-content": "start" }));
      state.compact = true;
      return state;
    },
    // settings page
    sSettingsCompanyState() {
      let view = this;

      let currentSetting = view.sExportSettings.find(s => s.id === view.selectedSettingsId);

      let state = this.$helpers.GetDataTableStateFromKeyLabelObject(
        {
          storeId: "Kostenstellen",
          employeeFinishedState: "Monatsabschlüsse erledigt / nicht erledigt",
        },
        view.stores
      );

      state.headerMap.storeId.width = "40%";
      state.headerMap.storeId.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "buttonc",
            label: data.name,
            type: "occupy-container",
            class: `${currentSetting.selectedStores.includes(data.id) ? "selected" : ""}`,
            tooltip: "Auswählen / Abwählen",
            customEvents: {
              click: () => {
                if (currentSetting.selectedStores.includes(data.id)) {
                  let index = currentSetting.selectedStores.indexOf(currentSetting.selectedStores.find(elem => elem === data.id));
                  currentSetting.selectedStores.splice(index, 1);
                } else {
                  currentSetting.selectedStores.push(data.id);
                }
                view.settingsUpdated = true;
              },
            },
            styleText: { "text-align": "left", width: "100%" },
          };
        },
      ];

      state.headerMap.employeeFinishedState.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "buttonc",
            label: "Monatsabschluss",
            type: "occupy-container",
            style: "pointer-events:none; box-shadow: inset  10px 0 0 -5px var(--bg)",
          };
        },
        (data, entry) => {
          return {
            componentType: "buttonc",
            icon: !view.monthlyStatements[data.id].loading && view.monthlyStatements[data.id].confirmed ? "fa-check" : "fa-times",
            type: "icon-overlay",
            style: "pointer-events:none;position: absolute; top: 0, left: initial !important; right: 120px !important; bottom: 0; margin: auto; transform: scale(.8)",
            styleIcon: {
              color: !view.monthlyStatements[data.id].loading && view.monthlyStatements[data.id].confirmed ? "var(--md)" : "var(--ml)",
            },
          };
        },
      ];
      Object.values(state.headerMap).forEach((h) => (h.stylesHeader = { "justify-content": "start" }));
      state.headerMap.employeeFinishedState.stylesHeader.paddingLeft = "5px";
      state.compact = true;
      return state;
    },
    sSettingsHeaderState() {
      let view = this;

      let currentSetting = view.sExportSettings.find(s => s.id === view.selectedSettingsId);

      let state = this.$helpers.GetDataTableStateFromKeyLabelObject(
        {
          listStructure: "Listenaufbau",
          columns: "Individuelle Spaltenbezeichnungen",
        },
        Object.values(this.availableFields)
      );

      state.headerMap.listStructure.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "buttonc",
            label: data.name,
            type: "occupy-container",
            tooltip: "Auswählen / Abwählen",
            class: `${currentSetting.availableFields.find(f => f.key === data.key)
                && currentSetting.availableFields.find(f => f.key === data.key).checked ? "selected" : ""}`,
            customEvents: {
              click: () => {
                let currentField = currentSetting.availableFields.find(f => f.key === data.key);
                if (currentField && currentField.checked) {
                  currentField.checked = false;
                } else if (currentField) {
                  currentField.checked = true;
                } else {
                  let newData = JSON.parse(JSON.stringify(data));
                  newData.checked = true;
                  currentSetting.availableFields.push(newData);
                }
                view.settingsUpdated = true;
              },
            },
            style: "height: calc(100% - 5px) !important; width: calc(100% - 8px) !important;",
            styleLabel: { "text-align": "left", width: "100%" },
          };
        },
      ];
      state.headerMap.columns.componentStateBuilders = [
        (data, entry) => {

          if (data.key.startsWith("balance")) {
            let balanceX = currentSetting.availableFields.find(f => f.key === data.key);
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.balanceItems,
                selectedItem: balanceX
                  ? balanceX.item
                  : view.availableFields[data.key].item,
                onceSelected: function ($event, item) {
                  if (!currentSetting.availableFields[data.key]) {
                    currentSetting.availableFields.push( JSON.parse(JSON.stringify(view.availableFields[data.key])));
                  }
                  balanceX = currentSetting.availableFields.find(f => f.key === data.key);
                  balanceX.item = item;
                  view.settingsUpdated = true;
                },
                styleLight: true,
                style: "",
              },
            };
          } else {
            return {
              componentType: "inputc",
              inputProp: currentSetting.availableFields.find(f => f.key === data.key) ? currentSetting.availableFields.find(f => f.key === data.key).newName : data.newName,
              placeholder: currentSetting.availableFields.find(f => f.key === data.key) ? currentSetting.availableFields.find(f => f.key === data.key).newName : data.newName,
              style: "text-align: left; box-shadow: inset 0 0 0 1px var(--contrast-3); height: calc(100% - 5px); width: calc(100% - 8px); top: 0px",
              customEvents: {
                Update: (val) => {
                  let currentField = currentSetting.availableFields.find(f => f.key === data.key);
                  if (currentField) {
                    currentField.newName = val;
                  } else {
                    let newData = JSON.parse(JSON.stringify(data));
                    newData.checked = false;
                    newData.newName = val;
                    currentSetting.availableFields.push(newData);
                  }
                  view.settingsUpdated = true;
                },
              },
            };
          }
        },
      ];
      Object.values(state.headerMap).forEach((h) => (h.stylesHeader = { "justify-content": "start" }));
      state.compact = true;
      return state;
    },
    sSettingsMonthlyAdditionalState() {
      let view = this;

      let currentSetting = view.sExportSettings.find(s => s.id === view.selectedSettingsId);

      let state = this.$helpers.GetDataTableStateFromKeyLabelObject(
        {
          header: "Listenelemente",
          columns: "Zusätzliche Einstellungen",
        },
          Object.values(this.availableFieldsAdditional)
      );

      state.headerMap.header.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "buttonc",
            label: data.name,
            type: "occupy-container",
            tooltip: "Auswählen / Abwählen",
            class: `${currentSetting && currentSetting.availableFieldsAdditional.find(f => f.key === data.key)
              && currentSetting.availableFieldsAdditional.find(f => f.key === data.key).checked ? "selected" : ""}`,
            customEvents: {
              click: () => {
                let currentField = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
                if (currentField && currentField.checked) {
                  currentField.checked = false;
                } else if (currentField) {
                  currentField.checked = true;
                } else {
                  let newData = JSON.parse(JSON.stringify(data));
                  newData.checked = true;
                  currentSetting.availableFieldsAdditional.push(newData);
                }
                view.settingsUpdated = true;
              },
            },
            style: "height: calc(100% - 5px) !important; width: calc(100% - 8px) !important;",
            styleLabel: { "text-align": "left", width: "100%" },
          };
        },
      ];
      state.headerMap.columns.componentStateBuilders = [
        (data, entry) => {

          if (data.key === "monthAdditional") {
            let monthAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.monthItems,
                selectedItem: monthAdditional ?
                    monthAdditional.item :
                    view.availableFieldsAdditional.monthAdditional.item,
                onceSelected: function ($event, item) {
                  if (!monthAdditional) {
                    currentSetting.availableFieldsAdditional.push(JSON.parse(JSON.stringify(view.availableFieldsAdditional.monthAdditional)));
                  }
                  monthAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
                  monthAdditional.item = item;
                  view.settingsUpdated = true;
                },
                styleLight: true,
                style: "",
              },
            };
          } else if (data.key === "paidWorkTimeAdditional") {
            let paidWorkTimeAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.paidWorkTimeAdditionalItems,
                selectedItem: paidWorkTimeAdditional ?
                    paidWorkTimeAdditional.item :
                    view.availableFieldsAdditional.paidWorkTimeAdditional.item,
                onceSelected: function ($event, item) {
                  if (!paidWorkTimeAdditional) {
                    currentSetting.availableFieldsAdditional.push(JSON.parse(JSON.stringify(view.availableFieldsAdditional.paidWorkTimeAdditional)));
                  }
                  paidWorkTimeAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
                  paidWorkTimeAdditional.item = item;
                  view.settingsUpdated = true;
                },
                styleLight: true,
                style: "",
              },
            };
          } else if (data.key === "headerAdditional") {
            let headerAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.headerAdditionalItems,
                selectedItem: headerAdditional ?
                    headerAdditional.item :
                    view.availableFieldsAdditional.headerAdditional.item,
                onceSelected: function ($event, item) {
                  if (!headerAdditional) {
                    currentSetting.availableFieldsAdditional.push(JSON.parse(JSON.stringify(view.availableFieldsAdditional.headerAdditional)));
                  }
                  headerAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
                  headerAdditional.item = item;
                  view.settingsUpdated = true;
                },
                styleLight: true,
                style: "",
              },
            };
          } else {
            return {};
          }
        },
      ];
      Object.values(state.headerMap).forEach((h) => (h.stylesHeader = { "justify-content": "start" }));
      state.compact = true;
      return state;
    },
    sSettingsDailyAdditionalState() {
      let view = this;

      let currentSetting = view.sExportSettings.find(s => s.id === view.selectedSettingsId);
      console.log(currentSetting);

      let state = this.$helpers.GetDataTableStateFromKeyLabelObject(
        {
          header: "Listenelemente",
          columns: "Zusätzliche Einstellungen",
        },
        Object.values(this.availableFieldsAdditional)
      );

      state.headerMap.header.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "buttonc",
            label: data.name,
            type: "occupy-container",
            tooltip: "Auswählen / Abwählen",
            class: `${currentSetting && currentSetting.availableFieldsAdditional.find(f => f.key === data.key)
              && currentSetting.availableFieldsAdditional.find(f => f.key === data.key).checked ? "selected" : ""}`,
            customEvents: {
              click: () => {
                let currentField = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
                if (currentField && currentField.checked) {
                  currentField.checked = false;
                } else if (currentField) {
                  currentField.checked = true;
                } else {
                  let newData = JSON.parse(JSON.stringify(data));
                  newData.checked = true;
                  currentSetting.availableFieldsAdditional.push(newData);
                }
                view.settingsUpdated = true;
              },
            },
            style: "height: calc(100% - 5px) !important; width: calc(100% - 8px) !important;",
            styleLabel: { "text-align": "left", width: "100%" },
          };
        },
      ];
      state.headerMap.columns.componentStateBuilders = [
        (data, entry) => {

          if (data.key === "dailyProcessingFlagAdditional") {
            let dailyProcessingFlagAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.exportDateItems,
                selectedItem: dailyProcessingFlagAdditional ?
                    dailyProcessingFlagAdditional.item :
                    view.availableFieldsAdditional.dailyProcessingFlagAdditional.item,
                onceSelected: function ($event, item) {
                  if (!dailyProcessingFlagAdditional) {
                    currentSetting.availableFieldsAdditional.push(JSON.parse(JSON.stringify(view.availableFieldsAdditional.dailyProcessingFlagAdditional)));
                  }
                  dailyProcessingFlagAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
                  dailyProcessingFlagAdditional.item = item;
                  view.settingsUpdated = true;
                },
                styleLight: true,
                style: "",
              },
            };
          } else if (data.key === "headerAdditional") {
            let headerAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.headerAdditionalItems,
                selectedItem: headerAdditional ?
                    headerAdditional.item :
                    view.availableFieldsAdditional.headerAdditional.item,
                onceSelected: function ($event, item) {
                  if (!headerAdditional) {
                    currentSetting.availableFieldsAdditional.push(JSON.parse(JSON.stringify(view.availableFieldsAdditional.headerAdditional)));
                  }
                  headerAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
                  headerAdditional.item = item;
                  view.settingsUpdated = true;
                },
                styleLight: true,
                style: ""
              },
            };
          } else if (data.key === "dailyPayoutValueAdditional") {
            let dailyPayoutValueAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.dailyPayoutValueAdditionalItems,
                selectedItem: dailyPayoutValueAdditional ?
                    dailyPayoutValueAdditional.item :
                    view.availableFieldsAdditional.dailyPayoutValueAdditional.item,
                onceSelected: function ($event, item) {
                  if (!dailyPayoutValueAdditional) {
                    currentSetting.availableFieldsAdditional.push(JSON.parse(JSON.stringify(view.availableFieldsAdditional.dailyPayoutValueAdditional)));
                  }
                  dailyPayoutValueAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
                  dailyPayoutValueAdditional.item = item;
                  view.settingsUpdated = true;
                },
                styleLight: true,
                style: ""
              },
            };
          } else if (data.key === "dailyPayoutHoursValueAdditional") {
            let dailyPayoutHoursValueAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.dailyPayoutHoursValueAdditionalItems,
                selectedItem: dailyPayoutHoursValueAdditional ?
                    dailyPayoutHoursValueAdditional.item :
                    view.availableFieldsAdditional.dailyPayoutHoursValueAdditional.item,
                onceSelected: function ($event, item) {
                  if (!currentSetting.availableFieldsAdditional.dailyPayoutHoursValueAdditional) {
                    currentSetting.availableFieldsAdditional.push( JSON.parse(JSON.stringify(view.availableFieldsAdditional.dailyPayoutHoursValueAdditional)));
                  }
                  dailyPayoutHoursValueAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
                  dailyPayoutHoursValueAdditional.item = item;
                  view.settingsUpdated = true;
                },
                styleLight: true,
                style: ""
              },
            };
          } else {
            return {};
          }
        },
      ];
      Object.values(state.headerMap).forEach((h) => (h.stylesHeader = { "justify-content": "start" }));
      state.compact = true;
      return state;
    },
    sSettingsPersonnelProvisionsAdditionalState() {
      let view = this;

      let currentSetting = view.sExportSettings.find(s => s.id === view.selectedSettingsId);
      console.log(currentSetting);

      let state = this.$helpers.GetDataTableStateFromKeyLabelObject(
        {
          header: "Listenelemente",
          columns: "Zusätzliche Einstellungen",
        },
        Object.values(this.availableFieldsAdditional)
      );

      state.headerMap.header.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "buttonc",
            label: data.name,
            type: "occupy-container",
            tooltip: "Auswählen / Abwählen",
            class: `${currentSetting && currentSetting.availableFieldsAdditional.find(f => f.key === data.key)
              && currentSetting.availableFieldsAdditional.find(f => f.key === data.key).checked ? "selected" : ""}`,
            customEvents: {
              click: () => {
                let currentField = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
                if (currentField && currentField.checked) {
                  currentField.checked = false;
                } else if (currentField) {
                  currentField.checked = true;
                } else {
                  let newData = JSON.parse(JSON.stringify(data));
                  newData.checked = true;
                  currentSetting.availableFieldsAdditional.push(newData);
                }
                view.settingsUpdated = true;
              },
            },
            style: "height: calc(100% - 5px) !important; width: calc(100% - 8px) !important;",
            styleLabel: { "text-align": "left", width: "100%" },
          };
        },
      ];
      state.headerMap.columns.componentStateBuilders = [
        (data, entry) => {

          if (data.key === "ppHeaderAdditional") {
            let headerAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.headerAdditionalItems,
                selectedItem: headerAdditional ?
                    headerAdditional.item :
                    view.availableFieldsAdditional.headerAdditional.item,
                onceSelected: function ($event, item) {
                  if (!headerAdditional) {
                    currentSetting.availableFieldsAdditional.push(JSON.parse(JSON.stringify(view.availableFieldsAdditional.headerAdditional)));
                  }
                  headerAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
                  headerAdditional.item = item;
                  view.settingsUpdated = true;
                },
                styleLight: true,
                style: ""
              },
            };
          } else if (data.key === "ppValuesAdditional") {
            let valuesAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.ppValuesAdditionalItems,
                selectedItem: valuesAdditional ?
                    valuesAdditional.item :
                    view.availableFieldsAdditional.valuesAdditional.item,
                onceSelected: function ($event, item) {
                  if (!valuesAdditional) {
                    currentSetting.availableFieldsAdditional.push(JSON.parse(JSON.stringify(view.availableFieldsAdditional.valuesAdditional)));
                  }
                  valuesAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
                  valuesAdditional.item = item;
                  view.settingsUpdated = true;
                },
                styleLight: true,
                style: ""
              },
            };
          } else if (data.key.startsWith("balance")) {
            let balanceXAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
            return {
              componentType: "dropdownsearch",
              state: {
                items: view.balanceAddtionItems,
                selectedItem: balanceXAdditional ?
                    balanceXAdditional.item :
                    view.availableFieldsAdditional[data.key].item,
                onceSelected: function ($event, item) {
                  if (!currentSetting.availableFieldsAdditional[data.key]) {
                    currentSetting.availableFieldsAdditional.push( JSON.parse(JSON.stringify(view.availableFieldsAdditional[data.key])));
                  }
                  balanceXAdditional = currentSetting.availableFieldsAdditional.find(f => f.key === data.key);
                  balanceXAdditional.item = item;
                  view.settingsUpdated = true;
                },
                styleLight: true,
                style: ""
              },
            };
          } else {
            return {};
          }
        },
      ];
      Object.values(state.headerMap).forEach((h) => (h.stylesHeader = { "justify-content": "start" }));
      state.compact = true;
      return state;
    },
    // export jobs overview
    // exportJobsFiltered() {
    //   return this.exportJobs.filter(j => j.type === (this.$parent.mode === "monthly" ? 0 : 1));
    // },
    // ---
    previewState() {
      let view = this;
      let nameMap = {};
      let additionalMap = {};
      let selectedStores = view.selectedStores;

      Object.values(this.availableFields).filter((a) => {
        if (a.checked) {
          if (view.$parent.mode === "personnelProvisions") {
            nameMap[a.key] = a.item ? a.item.label : a.newName;
          } else {
            nameMap[a.key] = a.newName;
          }
        }
      });

      let state = this.$helpers.GetDataTableStateFromKeyLabelObject(nameMap, view.exportTable);

      if (nameMap["externalCompanyId"])
        state.headerMap.externalCompanyId.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.externalCompanyId,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      if (nameMap["externalEmployeeId"])
        state.headerMap.externalEmployeeId.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.externalEmployeeId,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];

      if (nameMap["employeeName"])
        state.headerMap.employeeName.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.employeeName,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];

      if (nameMap["contentName"])
        state.headerMap.contentName.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.contentName,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];

      if (nameMap["lohnart"])
        state.headerMap.lohnart.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.lohnart,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];

      if (nameMap["paidWorkTime"])
        state.headerMap.paidWorkTime.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.paidWorkTime,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];

      if (nameMap["dailyExternalCompanyId"])
        state.headerMap.dailyExternalCompanyId.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.dailyExternalCompanyId,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      if (nameMap["dailyExternalEmployeeId"])
        state.headerMap.dailyExternalEmployeeId.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.dailyExternalEmployeeId,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      if (nameMap["dailyEmployeeName"])
        state.headerMap.dailyEmployeeName.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.dailyEmployeeName,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      if (nameMap["dailyLohnart"])
        state.headerMap.dailyLohnart.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.dailyLohnart,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      if (nameMap["dailyFrom"])
        state.headerMap.dailyFrom.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.dailyFrom,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      if (nameMap["dailyTo"])
        state.headerMap.dailyTo.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.dailyTo,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      if (nameMap["dailyPayoutValue"])
        state.headerMap.dailyPayoutValue.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.dailyPayoutValue,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      if (nameMap["dailyPayoutHoursValue"])
        state.headerMap.dailyPayoutHoursValue.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.dailyPayoutHoursValue,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      if (nameMap["dailyProcessingFlag"])
        state.headerMap.dailyProcessingFlag.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.dailyProcessingFlag,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      if (nameMap["dailyContentName"])
        state.headerMap.dailyContentName.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.dailyContentName,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      if (nameMap["month"]) {
        state.headerMap.month.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.month,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: {color: "black"},
            };
          },
        ];
      }

      if (nameMap["ppExternalCompanyId"]) {
        state.headerMap.ppExternalCompanyId.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.externalCompanyId,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      }
      if (nameMap["ppExternalEmployeeId"]) {
        state.headerMap.ppExternalEmployeeId.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.externalEmployeeId,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      }
      if (nameMap["ppEmployeeName"]) {
        state.headerMap.ppEmployeeName.componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data.employeeName,
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      }
      Object.keys(nameMap).filter((e) => e.startsWith("balance")).forEach(balance => {
        state.headerMap[balance].componentStateBuilders = [
          (data, entry) => {
            return {
              componentType: "labelc",
              text: data[balance],
              type: "occupy-container",
              style: `pointer-events:none; background: ${state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"}; box-shadow: 0 0 0 1px ${
                state.data.indexOf(data) % 2 === 0 ? "var(--contrast-2)" : "var(--contrast-3)"
              };`,
              styleText: { color: "black" },
            };
          },
        ];
      });



      state.compact = true;
      Object.values(state.headerMap).forEach(
        (h) =>
          (h.stylesHeader = (map) => {
            return {
              background: "var(--contrast-3)",
              height: "60px",
              marginTop: "-30px",
              "box-shadow":
                Object.values(state.headerMap).indexOf(map) === 0
                  ? "inset -10px -15px 0 -5px var(--bg),inset -10px 15px 0 -5px var(--bg)"
                  : Object.values(state.headerMap).indexOf(map) === Object.values(state.headerMap).length - 1
                  ? "inset 0px -15px 0 -5px var(--bg),inset 10px 15px 0 -5px var(--bg)"
                  : "inset 0px 0  0 5px var(--bg), inset 0px 5px  0 5px var(--bg), inset 0 -5px  0 5px var(--bg)",
            };
          })
      );
      state.rowStyles = (data, row, index) => {
        return {
          background: index % 2 === 0 ? "var(--contrast-3)" : "var(--contrast-2)",
        };
      };
      return state;
    },
    settingsEmployeesState() {
      let view = this;

      //let visibleSpots = view.filterDropdownOptions.filter(item => item.toggled).map(item => item.id);
      let nameFilter = this.employeeNameFilter;
      //let data = Object.values(wbMap).filter(wb => visibleSpots.includes(view.employeeMap[wb.employeeId].spotId)).map((e) => { return Object.assign({}, e)});
      let data = [];
      this.statementResponses.forEach(
        (s) =>
          (data = data.concat(
            s.filter((d) => {
              let employee = view.employeeMap[d.remoteId];
              return employee && (employee.firstName.indexOf(nameFilter) > -1 || employee.lastName.indexOf(nameFilter) > -1);
            })
          ))
      );

      data.sort(function (a, b) {
        let ea = view.employeeMap[a.remoteId];
        let eb = view.employeeMap[b.remoteId];
        if (view.sortByName) {
          if (ea != undefined && eb != undefined) {
            if (ea.lastName < eb.lastName) return -1;
            if (ea.lastName > eb.lastName) return 1;
            if (ea.lastName == eb.lastName && ea.firstName < eb.firstName) return -1;
            if (ea.lastName == eb.lastName && ea.firstName > eb.firstName) return 1;
          }
        } else {
          if (ea != undefined && eb != undefined) {
            let eaNum = ea.personnelId;
            let ebNum = eb.personnelId;
            if (eaNum != undefined && eaNum != null && ebNum != undefined && (eaNum != ebNum) != null) {
              eaNum = parseInt(eaNum);
              ebNum = parseInt(ebNum);
              if (eaNum < ebNum) return -1;
              if (eaNum > ebNum) return 1;
            } else if (eaNum == null || eaNum == undefined) {
              return 1;
            } else {
              return -1;
            }
          }
        }

        return 0;
      });

      data.forEach((d) => {
        d.subEntries = [
          {
            componentOverride: "monthlyclosingsummary",
            componentStateBuilders: [
              (data) => {
                return {
                  employeeData: d,
                  //enableWorkBalanceMultiplier: view.storeSettings.enableWorkBalanceMultiplier,
                  //prevMonthWorkBalance: view.previousMonthWorkBalanceMap[d.employeeId],
                  optionalColumn: false,
                  componentType: "monthlyclosingsummary",
                  customEvents: {
                    Update: () => {},
                  },
                };
              },
            ],
          },
        ];
      });
      //data.push(view.positiveBalanceSummaryData);

      let state = {};

      if (!this.sortByName) {
        state = this.$helpers.GetDataTableStateFromKeyLabelObject(
          {
            //personnelId: "PN",
            store: "Store",
            name: "Mitarbeiter",
            workTimeBalance: "Arbeitszeit Saldo",
            vacationTimeBalance: "Urlaub Saldo",
            holidayTimeBalance: "Feiertag Saldo",
            nightWorkTimeBalance: "Nachtarbeit Saldo",
            overTimeBalance: "Überstunden Saldo",
            actions: " ",
          },
          data
        );

        /*state.headerMap.personnelId.componentStateBuilders = [(data, entry) => {
                    let e = view.employeeMap[data.employeeId];
                    return {
                        componentType: 'labelc',
                        state:{ text: e && e.personnelId ? e.personnelId : '', },
                        styleLabel: {display: 'flex', alignItems: 'center', justifyContent: 'center',background: '', height: 'calc(100% - 10px)'},
                    }
                }];*/
      } else {
        state = this.$helpers.GetDataTableStateFromKeyLabelObject(
          {
            store: "Store",
            name: "Mitarbeiter",
            workTimeBalance: "Arbeitszeit Saldo",
            vacationTimeBalance: "Urlaub Saldo",
            holidayTimeBalance: "Feiertag Saldo",
            nightWorkTimeBalance: "Nachtarbeit Saldo",
            overTimeBalance: "Überstunden Saldo",
            actions: " ",
          },
          data
        );
      }

      state.headerMap.store.stylesEntrySum = { color: "black" };
      state.headerMap.store.componentStateBuilders = [
        (data, entry) => {
          /*let storeId = view.employeeMap[data.remoteId]
            ? view.employeeMap[data.remoteId].storeId
            : 0;*/

          let storeName = view.storesMap[data.storeId] ? view.storesMap[data.storeId].name : "";

          return {
            componentType: "labelc",
            state: {
              text: storeName,
            },
            styleLabel: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "",
              height: "calc(100% - 10px)",
            },
          };
        },
      ];

      state.headerMap.name.stylesEntrySum = { color: "black" };
      state.headerMap.name.componentStateBuilders = [
        (data, entry) => {
          let employee = view.employeeMap[data.remoteId] ? view.employeeMap[data.remoteId] : { firstName: "Unbekannt", lastName: "Unbekannt" };

          return {
            componentType: "employeeentry",
            state: {
              firstName: employee.firstName,
              lastName: employee.lastName,
              employee: employee,
              splitName: true,
              hideShadow: true,
              textStyle: { color: "white", fontSize: "12px", margin: "2.5px" },
            },
          };
        },
      ];

      state.headerMap.workTimeBalance.stylesEntrySum = { color: "black" };
      state.headerMap.workTimeBalance.componentStateBuilders = [
        (data, entry) => {
          if (data.confirmed || !view.canEditBalances) {
            return {
              componentType: "labelc",
              state: {
                text: (data.workTimeBalance / 60.0).toFixed(2),
              },
              styleLabel: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "",
                height: "calc(100% - 10px)",
              },
            };
          } else {
            return {
              componentType: "inputc",
              inputProp: (data.workTimeBalance / 60).toFixed(2),
              customEvents: {
                Update: (input, inputPrev) => {
                  view.dataUpdatedByUser(input, data, "workTimeBalance", 60);
                },
              },
              DisplayLabel: (value) => {
                let val = parseFloat(value);
                return val != null ? val.toFixed(2) : 0.0;
              },
              style: {
                background: "white",
                height: "calc(100% - 10px)",
                width: "calc(100% - 10px)",
                margin: "auto",
                right: "0",
                left: "0",
              },
            };
          }
        },
      ];

      state.headerMap.vacationTimeBalance.stylesEntrySum = { color: "black" };
      state.headerMap.vacationTimeBalance.componentStateBuilders = [
        (data, entry) => {
          if (data.confirmed || !view.canEditBalances) {
            return {
              componentType: "labelc",
              state: {
                text: (data.vacationTimeBalance / 60 / 24).toFixed(2),
              },
              styleLabel: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "",
                height: "calc(100% - 10px)",
              },
            };
          } else {
            return {
              componentType: "inputc",
              inputProp: (data.vacationTimeBalance / 60 / 24).toFixed(2),
              customEvents: {
                Update: (input, inputPrev) => {
                  view.dataUpdatedByUser(input, data, "vacationTimeBalance", 60 * 24);
                },
              },
              DisplayLabel: (value) => {
                let val = parseFloat(value);
                return val != null ? val.toFixed(2) : 0.0;
              },
              style: {
                background: "white",
                height: "calc(100% - 10px)",
                width: "calc(100% - 10px)",
                margin: "auto",
                right: "0",
                left: "0",
              },
            };
          }
        },
      ];

      state.headerMap.holidayTimeBalance.stylesEntrySum = { color: "black" };
      state.headerMap.holidayTimeBalance.componentStateBuilders = [
        (data, entry) => {
          if (data.confirmed || !view.canEditBalances) {
            return {
              componentType: "labelc",
              state: {
                text: (data.holidayTimeBalance / 60.0).toFixed(2),
              },
              styleLabel: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "",
                height: "calc(100% - 10px)",
              },
            };
          } else {
            return {
              componentType: "inputc",
              inputProp: (data.holidayTimeBalance / 60.0).toFixed(2),
              customEvents: {
                Update: (input, inputPrev) => {
                  view.dataUpdatedByUser(input, data, "holidayTimeBalance", 60);
                },
              },
              DisplayLabel: (value) => {
                let val = parseFloat(value);
                return val != null ? val.toFixed(2) : 0.0;
              },
              style: {
                background: "white",
                height: "calc(100% - 10px)",
                width: "calc(100% - 10px)",
                margin: "auto",
                right: "0",
                left: "0",
              },
            };
          }
        },
      ];

      state.headerMap.nightWorkTimeBalance.stylesEntrySum = { color: "black" };
      state.headerMap.nightWorkTimeBalance.componentStateBuilders = [
        (data, entry) => {
          if (data.confirmed || !view.canEditBalances) {
            return {
              componentType: "labelc",
              state: {
                text: (data.nightWorkTimeBalance / 60.0).toFixed(2),
              },
              styleLabel: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "",
                height: "calc(100% - 10px)",
              },
            };
          } else {
            return {
              componentType: "inputc",
              inputProp: (data.nightWorkTimeBalance / 60.0).toFixed(2),
              customEvents: {
                Update: (input, inputPrev) => {
                  view.dataUpdatedByUser(input, data, "nightWorkTimeBalance", 60);
                },
              },
              DisplayLabel: (value) => {
                let val = parseFloat(value);
                return val != null ? val.toFixed(2) : 0.0;
              },
              style: {
                background: "white",
                height: "calc(100% - 10px)",
                width: "calc(100% - 10px)",
                margin: "auto",
                right: "0",
                left: "0",
              },
            };
          }
        },
      ];

      state.headerMap.overTimeBalance.stylesEntrySum = { color: "black" };
      state.headerMap.overTimeBalance.componentStateBuilders = [
        (data, entry) => {
          if (data.confirmed || !view.canEditBalances || data.employeeId == "positiveSum") {
            return {
              componentType: "labelc",
              state: {
                text: (data.overTimeBalance / 60.0).toFixed(2),
              },
              styleLabel: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "",
                height: "calc(100% - 10px)",
              },
            };
          } else {
            return {
              componentType: "inputc",
              inputProp: (data.overTimeBalance / 60.0).toFixed(2),
              customEvents: {
                Update: (input, inputPrev) => {
                  view.dataUpdatedByUser(input, data, "overTimeBalance", 60);
                },
              },
              DisplayLabel: (value) => {
                let val = parseFloat(value);
                return val != null ? val.toFixed(2) : 0.0;
              },
              style: {
                background: "white",
                height: "calc(100% - 10px)",
                width: "calc(100% - 10px)",
                margin: "auto",
                right: "0",
                left: "0",
              },
            };
          }
        },
      ];

      let entries = Object.entries(state.headerMap);

      let mainColumns = ["workTimeBalance", "vacationTimeBalance", "holidayTimeBalance", "nightWorkTimeBalance", "overTimeBalance"];
      let mainColumnWidth = 15;
      let nameColumns = ["name"];
      let nameColumnWidth = this.sortByName ? 15 : 10;
      let persColumns = ["personnelId"];
      let persColumnWidth = 5;
      let actionColumns = ["actions"];
      let actionColumnWidth = 10;
      for (let x in entries) {
        if (mainColumns.includes(entries[x][0])) {
          entries[x][1].width = mainColumnWidth + "%";
        } else if (nameColumns.includes(entries[x][0])) {
          entries[x][1].width = nameColumnWidth + "%";
        } else if (persColumns.includes(entries[x][0])) {
          entries[x][1].width = persColumnWidth + "%";
        } else if (actionColumns.includes(entries[x][0])) {
          entries[x][1].width = actionColumnWidth + "%";
        }
        //entries[x][1].width = ((100 / (entries.length + 1)) * (x === (''+(entries.length-1)) ? 0.5 : 1)) + '%';
        //console.log(entries[x][1].width)
        entries[x][1].key = entries[x][0];
      }
      state.headerStyles = {
        top: 0,
        zIndex: 10,
        background: "var(--sub-menu)",
      };
      state.heightSubRow = "fit-content";
      state.rowStyles = { background: "var(--ml)" };
      state.rowStylesCollapsed = { background: "var(--contrast-1)" };
      state.sumRowStyles = { background: "var(--sub-menu)" };
      //state.dataSum = [this.positiveBalanceSummary, this.negativeBalanceSummary, this.balanceSummary];
      return state;
    },
    burgerMenuState(){
      let view = this;
      let state = {
        componentref: 'burgermenustate',
        groups: [],
        items: []
      };
      let group1 = {
        label: "Zusätzliche Downloads",
        items: [

          {
            closeOnClick: true,
            icon: "fa-file-download",
            show: view.softwareId === 0 && ['monthly', 'individual-wagetypes'].includes(view.$parent.mode),
            text: 'BMD Import Schnittstelle für Monatssummen herunterladen',
            click: () => { view.downloadMFSBmdInterface(); },
          },
          {
            closeOnClick: true,
            icon: "fa-file-download",
            show: view.softwareId === 0 && ['monthly', 'individual-wagetypes'].includes(view.$parent.mode),
            text: 'BMD Monatssummen Import Makro und Stapel herunterladen',
            click: () => { view.openMfsImportStapelPopup(); },
          },
          {
            closeOnClick: true,
            icon: "fa-file-download",
            show: view.softwareId === 0 && view.$parent.mode === 'daily',
            text: 'BMD Import Schnittstelle für Nichtleistungszeiten herunterladen',
            click: () => { view.downloadNLZBmdInterface(); },
          },
          {
            closeOnClick: true,
            icon: "fa-file-download",
            show: view.softwareId === 0 && view.$parent.mode === 'daily',
            text: 'BMD Nichtleistungszeiten Import Makro und Stapel herunterladen',
            click: () => { view.openNlzImportStapelPopup(); },
          },
          {
            closeOnClick: true,
            icon: "fa-file-pdf",
            text: 'Arbeitszeitkonto generieren',
            show:  view.subPage === 'export',
            disabled: false,
            click: () => { view.generateWorkTimeOverviewPdfReport() }
          },
        ]
      }

      state.groups.push(group1);

      return state;
    },
    financialAccountingGroupsById(){
      let view = this;
      let financialAccountingGroupsById = {};
      Object.values(view.financialAccountingGroups).forEach(e => {
        financialAccountingGroupsById[e.id] = e;
      });
      return financialAccountingGroupsById;
    },
    financialAccountingGroupsByNumber(){
      let view = this;
      let financialAccountingGroupsById = {};
      Object.values(view.financialAccountingGroups).forEach(e => {
        financialAccountingGroupsById[e.number] = e;
      });
      return financialAccountingGroupsById;
    },
  },
  methods: {
    getEmployeeContracts: function () {
      let view = this;
      let url = "/api/sec/proxy/md/employee/contracts";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/api/sec/proxy/md/employee/contracts");
      return this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      });
    },
    DragStart(panelDragged) {
      let view = this;
      this.panelDragged = panelDragged;
      this.isDragging = true;
      requestAnimationFrame(() => {
        view.DragWatch();
      });
    },
    DragWatch() {
      let view = this;
      let panel = window.mouse.element.closest(".panel");
      if (panel && panel.id !== this.panelDragged) {
        let order = this.orderStates[this.panelDragged];
        let orderHovered = this.orderStates[panel.id];
        if (order < orderHovered) {
          for (let state in this.orderStates) {
            let orderTemp = this.orderStates[state];
            if (orderTemp > orderHovered) {
              this.orderStates[state] = this.orderStates[state] + 1;
            }
          }
          this.orderStates[this.panelDragged] = orderHovered + 1;
        } else {
          for (let state in this.orderStates) {
            let orderTemp = this.orderStates[state];
            if (orderTemp >= orderHovered) {
              this.orderStates[state] = this.orderStates[state] + 1;
            }
          }
          this.orderStates[this.panelDragged] = orderHovered;
        }
      }
      if (window.mouse.click) {
        requestAnimationFrame(() => {
          view.DragWatch();
        });
      } else {
        view.DragEnd();
      }
    },
    DragEnd() {
      let localOrder = localStorage.setItem("localOrderExportBuilderPanels", JSON.stringify(this.orderStates));
      this.isDragging = false;
      this.panelDragged = "";
    },
    refresh: function (force = false, number = 0) {
      let view = this;
      console.log("refresh " + number);
      view.sessionId = crypto.randomUUID();
      view.somethingHasChangedWhatAffectsExportTable = true;
      view.loading = true;
      view.settingsUpdated = false;
      view.subPage = 'export';
      view.sExportSettings = [];
      view.baseSettings = {
        enablePeriodicExport: false,
        enableMonthlyClosingExport: false,
        enableExportViaTransferService: false,
        periodicExportTimes: [],
        monthlyClosingExportSettings: [],
      };


      let promises = [];
      promises.push(view.loadExportJobs());
      promises.push(view.loadWageTypes().then((response) => {
          // view.loadWageTypesMapping();
        })
      );
      promises.push(view.loadFinancialAccountingGroups());
      promises.push(view.loadExportSettings(view.$parent.mode));
      promises.push(view.$helpers
        .GetCall("/api/sec/proxy/md/storeSettings", {})
        .then((response) => {
          for (let i in response.data) {
            let setting = response.data[i];
            view.storeSettingWeekdaysMap[setting.id] = setting.workDays;
          }
        })
        .catch((e) => {
          console.log(e);
        })
      );
      promises.push(
        view.$helpers
            .GetCompanySettings(force)
            .then((response) => {
              if (response.data[0].bmdId != null) {
                view.bmdId = response.data[0].bmdId;
              }
              if (response.data[0].softwareId !== null) {
                view.softwareId = response.data[0].softwareId;
              }
            })
            .catch((error) => {
              console.log(error);
            })
      );
      promises.push(view.loadEmployees(force));
      promises.push(view.loadStoresForCompany());

      Promise.all(promises)
      .then((responses) => {
        view.$forceUpdate();
        this.loading = false;
        view.loadAllMonthlyStatements(); // load these for Arbeitszeitkonto
      });
    },
    downloadMFSBmdInterface() {
      let view = this;

      let resourceName = "ml_abrechnungsdaten_import.zip";
      view.loadResourceFile("bmd", resourceName)
      .then((resp) => {
        let content = view.$helpers.convertToUInt8(atob(resp.data.content));
        view.$helpers.forceFileDownload(content, resourceName, true); //resp.data.name, true);
      });
    },
    downloadNLZBmdInterface() {
      let view = this;

      let resourceName = "ml_nichtleistungszeiten_import.zip";
      view.loadResourceFile("bmd", resourceName)
      .then((resp) => {
        let content = view.$helpers.convertToUInt8(atob(resp.data.content));
        view.$helpers.forceFileDownload(content, resourceName, true); //resp.data.name, true);
      });
    },
    openMfsImportStapelPopup() {
      var view = this;

      view.$helpers.OpenPopup({
        elRelative: null,
        remainAfterSelect: true,
        type: 'datafill',
        componentState: {
          description:
              'Öffnen Sie das Fenster "Makros" im Menü "Tools" in BMD.' +
              '<br>Wählen Sie nun in der Menüleiste des Fensters "Extras" -> "Importieren" aus. Wählen Sie nun die heruntergeladene XML Macro Datei aus.' +
              '<br><br>Öffnen Sie nun die Stapeldefinition.' +
              '<br>Importieren Sie die ZIP Datei via "Extras" -> "Ganzen Stapel importieren".' +
              '<br>Ändern Sie die Parameter wie folgt:' +
              '<br><br>Reihenfolge 1: Lohn-Basiseinstellungen setzen:' +
              '<br>Ändern Sie STP_HRS_DBSNR auf die Datenbestandsnummer, die Sie in Ihren Lohn-Basiseinstellungen finden. ' +
              'Die Firma, für die Sie diesen Stapel einrichten muss sich in diesem Datenbestand befinden.' +
              '<br><br>Reihenfolge 2: Firmenname:' +
              '<br>Ändern Sie die Pfade, damit sie auf Ihre Dateistruktur passt. Diese Pfade sollen auf die Ordnerstruktur zeigen, welche Sie hier in ML herunterladen können.' +
              '<br>Ändern Sie pSchnittstellenName auf den Namen der Schnittstelle, welche für Ihren Abrechnungen Import aus ML verwendet werden soll.' +
              '<br>Ändern Sie pFirma auf die Firmennummer, für die Sie diesen Stapel benutzen möchten.' +
              '<br>Ändern Sie pMailEmpfaenger auf die Email Adresse, welche Benachrichtigungen zu Fehlern empfangen soll. ' +
              'Mit den Parametern pWarnungen und pError können Sie steuern welche Arten von Fehlern gesendert werden sollen (1 = senden, 0 = nicht senden).' +
              '<br>Ändern Sie pPathLogs auf den Pfad Ihrer Logdateien. Sie finden den Ort der Logdateien in dem Sie im Fenster "Dienstnehmer generieren mittels Importdatei"' +
              'auf "Funktionen" -> "Logdatei ansehen" klicken. Weiters klicken Sie im neuen Fenster auf "Funktionen" -> "LOG-Dateien öffnen".' +
              '<br><br>Kopieren Sie den Firmeneintrag für alle Firmen, die Sie importieren möchten.' +
              '<br>Kopieren Sie gegebenfalls die Lohn-Basiseinstellungen setzen, wenn sich Firmen in unterschiedlichen Datenbeständen befinden.' +
              '<br><br>Der Stapel kann nun getestet und automatisiert werden.' +
              '<br>Wenden Sie sich dazu an Ihren MeineLohnabrechnung Berater oder erstellen Sie ein Ticket.' +
              '<br>',
          entries: [],
          positiveButtonLabel: "MAKRO herunterladen",
          negativeButtonLabel: "STAPEL herunterladen",
          selectCallback: function (result, event) {
            view.downloadMfsImportMacro();
          },
          deleteCallback: function (result, event) {
            view.downloadMfsImportStapel();
          },
          errorCheck: {
          },
        },
        header: 'BMD Monatssummen Import Makro und Stapel herunterladen',
        expanded: true,
        centered: true,
      });
    },
    downloadMfsImportMacro() {
      let view = this;

      let resourceName = "BMD__FOR_FORMEL_972762356298055_abr_import.XML";
      view.loadResourceFile("bmd", resourceName)
      .then((resp) => {
        let content = view.$helpers.convertToUInt8(atob(resp.data.content));
        view.$helpers.forceFileDownload(content, resourceName, true); //resp.data.name, true);
      });
    },
    downloadMfsImportStapel() {
      let view = this;

      let resourceName = "mfs_import_stapel.zip";
      view.loadResourceFile("bmd", resourceName)
      .then((resp) => {
        let content = view.$helpers.convertToUInt8(atob(resp.data.content));
        view.$helpers.forceFileDownload(content, resourceName, true); //resp.data.name, true);
      });
    },
    openNlzImportStapelPopup() {
      var view = this;

      view.$helpers.OpenPopup({
        elRelative: null,
        remainAfterSelect: true,
        type: 'datafill',
        componentState: {
          description:
              'Öffnen Sie das Fenster "Makros" im Menü "Tools" in BMD.' +
              '<br>Wählen Sie nun in der Menüleiste des Fensters "Extras" -> "Importieren" aus. Wählen Sie nun die heruntergeladene XML Macro Datei aus.' +
              '<br><br>Öffnen Sie nun die Stapeldefinition.' +
              '<br>Importieren Sie die ZIP Datei via "Extras" -> "Ganzen Stapel importieren".' +
              '<br>Ändern Sie die Parameter wie folgt:' +
              '<br><br>Reihenfolge 1: Lohn-Basiseinstellungen setzen:' +
              '<br>Ändern Sie STP_HRS_DBSNR auf die Datenbestandsnummer, die Sie in Ihren Lohn-Basiseinstellungen finden. ' +
              'Die Firma, für die Sie diesen Stapel einrichten muss sich in diesem Datenbestand befinden.' +
              '<br><br>Reihenfolge 2: Firmenname:' +
              '<br>Ändern Sie die Pfade, damit sie auf Ihre Dateistruktur passt. Diese Pfade sollen auf die Ordnerstruktur zeigen, welche Sie hier in ML herunterladen können.' +
              '<br>Ändern Sie pSchnittstellenName auf den Namen der Schnittstelle, welche für Ihren Nichtleistungszeiten Import aus ML verwendet werden soll.' +
              '<br>Ändern Sie pFirma auf die Firmennummer, für die Sie diesen Stapel benutzen möchten.' +
              '<br>Ändern Sie pMailEmpfaenger auf die Email Adresse, welche Benachrichtigungen zu Fehlern empfangen soll. ' +
              'Mit den Parametern pWarnungen und pError können Sie steuern welche Arten von Fehlern gesendert werden sollen (1 = senden, 0 = nicht senden).' +
              '<br>Ändern Sie pPathLogs auf den Pfad Ihrer Logdateien. Sie finden den Ort der Logdateien in dem Sie im Fenster "Dienstnehmer generieren mittels Importdatei"' +
              'auf "Funktionen" -> "Logdatei ansehen" klicken. Weiters klicken Sie im neuen Fenster auf "Funktionen" -> "LOG-Dateien öffnen".' +
              '<br><br>Kopieren Sie den Firmeneintrag für alle Firmen, die Sie importieren möchten.' +
              '<br>Kopieren Sie gegebenfalls die Lohn-Basiseinstellungen setzen, wenn sich Firmen in unterschiedlichen Datenbeständen befinden.' +
              '<br><br>Der Stapel kann nun getestet und automatisiert werden.' +
              '<br>Wenden Sie sich dazu an Ihren MeineLohnabrechnung Berater oder erstellen Sie ein Ticket.' +
              '<br>',
          entries: [],
          positiveButtonLabel: "MAKRO herunterladen",
          negativeButtonLabel: "STAPEL herunterladen",
          selectCallback: function (result, event) {
            view.downloadNlzImportMacro();
          },
          deleteCallback: function (result, event) {
            view.downloadNlzImportStapel();
          },
          errorCheck: {
          },
        },
        header: 'BMD Nichtleistungszeiten Import Makro und Stapel herunterladen',
        expanded: true,
        centered: true,
      });
    },
    downloadNlzImportMacro() {
      let view = this;

      let resourceName = "BMD__FOR_FORMEL_821162340489558_nlz_import.XML";
      view.loadResourceFile("bmd", resourceName)
      .then((resp) => {
        let content = view.$helpers.convertToUInt8(atob(resp.data.content));
        view.$helpers.forceFileDownload(content, resourceName, true); //resp.data.name, true);
      });
    },
    downloadNlzImportStapel() {
      let view = this;

      let resourceName = "absences_import_stapel.zip";
      view.loadResourceFile("bmd", resourceName)
      .then((resp) => {
        let content = view.$helpers.convertToUInt8(atob(resp.data.content));
        view.$helpers.forceFileDownload(content, resourceName, true); //resp.data.name, true);
      });
    },
    loadResourceFile(resourceType, resourceName) {
      let url = "/api/sec/resource";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/api/sec/resource");
      url = this.$helpers.appendParam("resourceType", resourceType, url, "/api/sec/resource");
      url = this.$helpers.appendParam("resourceName", resourceName, url, "/api/sec/resource");
      return this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      });
    },

    doCsvDownload() {
      let view = this;
      let filename = "";
      ["monthly", "individual-wagetypes"].includes(view.$parent.mode) ? (filename = "CSV Export - Monatssummen")
        : this.$parent.mode == 'daily' ? (filename = "CSV Export - Abwesenheiten")
        : (filename = "CSV Export - Rückstellungen");

      filename = filename + " - " + view.monthNames[view.timeframeStart.months()] + " " + view.timeframeStart.format("YYYY");

      view.$helpers.forceFileDownload(view.currentCSV, filename + ".csv");
    },
    triggerCsvExportWithFrontendSettings() {
      let view = this;

      let baseUrl = '';
      ["monthly", "individual-wagetypes"].includes(view.$parent.mode) ? (baseUrl = '/api/sec/bmd/exports/MFS/dispatch/withFile')
          : this.$parent.mode == 'daily' ? (baseUrl = '/api/sec/bmd/exports/AS/dispatch/withFile')
          : (baseUrl = '/api/sec/bmd/exports/PP/dispatch/withFile');

      let url = baseUrl;
      let year = view.selectedDate.format("YYYY");
      let month = view.selectedDate.format("MM");

      let data = {
        companyId: this.$store.state.companyId,
        year: year,
        month: month,
        file: view.$helpers.b64EncodeUnicode(view.currentCSV),
      };

      this.axios({
        method: 'post',
        data: data,
        headers: {
          'AUTHORIZATION': "Bearer "+this.$store.state.jwt
        },
        url: url
      })
      .then((response) => {
        view.$helpers.success("Erfolg", "Export wurde erfolgreich angestoßen.");
      })
      .catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler", "Export konnte nicht angstoßen werden.");
      })
      .finally((fin) => {

      });

    },
    triggerCsvExport(entity) {
      let view = this;

      let baseUrl = '';
      ["monthly", "individual-wagetypes"].includes(view.$parent.mode) ? (baseUrl = '/api/sec/bmd/exports/MFS/dispatch/withSetting')
          : this.$parent.mode == 'daily' ?  (baseUrl = '/api/sec/bmd/exports/AS/dispatch/withSetting')
          : (baseUrl = '/api/sec/bmd/exports/PP/dispatch/withSetting');

      let url = baseUrl;

      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);
      url = this.$helpers.appendParam("year", entity.year, url, baseUrl);
      url = this.$helpers.appendParam("month", entity.month, url, baseUrl);
      url = this.$helpers.appendParam("exportSettingsId", entity.exportSettingsId, url, baseUrl);

      this.axios({
        method: 'post',
        data: {},
        headers: {
          'AUTHORIZATION': "Bearer "+this.$store.state.jwt
        },
        url: url
      })
      .then((response) => {
        view.$helpers.success("Erfolg", "Export wurde erfolgreich angestoßen.");
      })
      .catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler", "Export konnte nicht angstoßen werden.");
      })
      .finally((fin) => {

      });

    },
    getCsvExportFromBackend(entity) {
      let view = this;

      let baseUrl = '';
      ["monthly"].includes(view.$parent.mode) ? (baseUrl = '/api/sec/bmd/exports/MFS/create')
          : this.$parent.mode == 'individual-wagetypes' ?  (baseUrl = '/api/sec/bmd/exports/IWT/create')
          : this.$parent.mode == 'daily' ?  (baseUrl = '/api/sec/bmd/exports/AS/create')
          : (baseUrl = '/api/sec/bmd/exports/PP/create');

      let url = baseUrl;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);

      return this.axios({
        method: 'post',
        data: entity,
        headers: {
          'AUTHORIZATION': "Bearer "+this.$store.state.jwt
        },
        url: url
      });

    },
    openCsvExportWithSettingsPopup() {
      let view = this;

      let tmpMoment = view.$helpers.getCurrentMonthMomentTZ();
      let tmpMomentMinusOne = tmpMoment.clone().subtract(1, "months");

      let years = [];
      for (let i = 0; i < 3; i++) {
        let current = tmpMoment.year() - i;
        years.push({value: current, label: current + ""});
      }

      let months = [
        {value: 1, label: 'Jänner'},
        {value: 2, label: 'Februar'},
        {value: 3, label: 'März'},
        {value: 4, label: 'April'},
        {value: 5, label: 'Mai'},
        {value: 6, label: 'Juni'},
        {value: 7, label: 'Juli'},
        {value: 8, label: 'August'},
        {value: 9, label: 'September'},
        {value: 10, label: 'Oktober'},
        {value: 11, label: 'November'},
        {value: 12, label: 'Dezember'}
      ];

      let exportEntries = [];
      let currentSetting = null;
      for (let i = 0; i < view.sExportSettings.length; i++) {
        let setting = view.sExportSettings[i];
        let settingEntry = {value: setting.id, label: setting.name};
        if (setting.id === view.selectedSettingsId) {
          currentSetting = settingEntry;
        }
        exportEntries.push(settingEntry);
      }

      let entries = [
        {
          label: "Jahr",
          key: "year",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: years,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: years.find(y => { return y.value === tmpMomentMinusOne.year() }),
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: years.find(y => { return y.value === tmpMomentMinusOne.year() }).value,
        },
        {
          label: "Monat",
          key: "month",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: months,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: months.find(y => { return y.value === tmpMomentMinusOne.month()+1 }),
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: months.find(y => { return y.value === tmpMomentMinusOne.month()+1 }).value,
        },
        {
          label: "Export Einstellung",
          key: "exportSettingsId",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: exportEntries,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: currentSetting,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: currentSetting.value,
        },
      ];

      view.$helpers.OpenPopup({
        type: "datafill",
        componentState: {
          entries: entries,
          selectCallback: function (entity) {
            view.triggerCsvExport(entity);
          },
        },
        header: "Export Einstellungen",
        expanded: true,
        centered: true,
      });
    },

    loadWageTypes: function() {
      let view = this;
      if (["monthly", "daily", "personnelProvisions"].includes(view.$parent.mode)) {
        return view.loadMdWageTypes();
      } else if (view.$parent.mode == "individual-wagetypes") {
        let promises = [];
        promises.push(view.loadIndividualWageTypes());
        promises.push(view.loadEmploymentModels());
        return Promise.all(promises);
      }
    },
    loadIndividualWageTypes: function () {
      let view = this;
      let params = { companyId: view.$store.state.companyId };

      return view.$helpers
        .GetCall("/api/sec/individualWageTypes", params)
        .then((response) => {
          let data = response.data.data;
          let tmp = [];
          // Looping through sorted data and creating objects
          for (let i = 0; i < data.length; i++) {
            let item = {
              id: data[i].id,
              enumKey: data[i].id,
              numberString: data[i].numberString,
              name: data[i].name,
              sorting: data[i].sorting,
              color: data[i].color,
              details: data[i].details,
              checked: true,
            };
            tmp.push(item);
          }
          view.content = tmp;

          view.$helpers.GetExportSelectedWagetypesIndividualWagetypes().then((data) => {
            for (let i in view.content) {
              if (data[view.content[i].enumKey]) {
                view.content[i].checked = data[view.content[i].enumKey].checked;
              }
            }
          });
        })
        .catch((e) => {
          view.loading = false;
          console.log(e);
        });
    },
    loadEmploymentModels: function () {
      let view = this;
      let params = { companyId: view.$store.state.companyId };

      view.$helpers
        .GetCall("/api/sec/employmentModels", params)
        .then((response) => {
          let data = response.data.data;
          let employmentModels = [];
          for (let i = 0; i < data.length; i++) {
            let employmentModel = {
              id: data[i].id,
              number: data[i].number,
              name: data[i].name,
              sorting: data[i].sorting,
              color: data[i].color,
              checked: true,
            };
            if(data[i].isActive) {
              employmentModels.push(employmentModel);
            }
          }
          view.employmentModelArray = employmentModels;
        })
        .catch((e) => {
          view.loading = false;
          console.log(e);
        });
    },
    loadMdWageTypes: function () {
      let view = this;
      return view.$helpers
      .GetCall("/api/sec/wagetypes", {})
      .then((response) => {
        view.content = [];
        console.log(view.$parent.mode);
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].enumKey = response.data[i].id;
          response.data[i].checked = false;

          if (response.data[i].monthly && ["monthly", "personnelProvisions"].includes(view.$parent.mode)) {
            view.content.push(response.data[i]);
            if (["monthly"].includes(view.$parent.mode)) {
              view.$helpers.GetExportSelectedWagetypesMonthly().then((data) => {
                for (let i in view.content) {
                  if (data[view.content[i].enumKey]) {
                    view.content[i].checked = data[view.content[i].enumKey].checked;
                  }
                }
              });
            } else if (view.$parent.mode == "personnelProvisions") {
              view.balanceItems = view.content.filter((item) => item.key.endsWith("Balance")).map((item) => {
                return {
                  key: item.id,
                  label: item.name
                };
              });
            }
          } else if (!response.data[i].monthly && view.$parent.mode == "daily") {
            view.content.push(response.data[i]);
            view.$helpers.GetExportSelectedWagetypesDaily().then((data) => {
              for (let i in view.content) {
                if (data[view.content[i].enumKey]) {
                  view.content[i].checked = data[view.content[i].enumKey].checked;
                }
              }
            });
          }
        }
        console.log("content", view.content);
      })
      .catch((e) => {
        console.log(e);
      });
    },
    loadWageTypesMapping: function () {
      let view = this;
      view.$helpers
        .GetCall("/api/sec/mapping/wagetype", {})
        .then((response) => {
          for (let i = 0; i < view.content.length; i++) {
            view.content[i].externalId = "";
            for (let j = 0; j < response.data.data.length; j++) {
              let tmp = response.data.data[j];
              if (parseInt(view.content[i].enumKey) == parseInt(tmp.wageTypeId)) {
                view.content[i].externalId = tmp.externalId;
              }
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    loadFinancialAccountingGroups: function(){
      let view = this;
      let params = { companyId: view.$store.state.companyId };
      view.$helpers.GetCall('/api/financialaccountinggroups', params).then((response) => {
        let data = response.data.data;
        let tmp = [];
        for (let i = 0; i < data.length; i++) {
          let item = {
            id: data[i].id,
            number: data[i].number,
            name: data[i].name,
            sorting: data[i].sorting,
            color: data[i].color,
          };
          tmp.push(item);
        }
        view.financialAccountingGroups = tmp;
      }).catch((e) => {
        console.log(e);
      });
    },

    async loadAllMonthlyStatements() {
      let view = this;
      view.prevMonthStatementResponses = [];
      view.statementResponses = [];
      view.employeeWorkDaysCount = [];

      console.log("stores", view.stores);
      console.log("storesMap", view.storesMap);

      for (let j = 0; j < view.stores.length; j++) {
        console.log(view.stores[j].id);
        await this.loadMonthlyStatementFromMD(view.stores[j].id);
        await this.loadPrevMonthlyStatementFromMD(view.stores[j].id);
        // await this.loadStatisticsDynamicActualShifts(view.stores[j].id);
      }

      // console.log(view.statementResponses);

      view.checkDuplicateEmployees();
      if (["monthly", "individual-wagetypes"].includes(view.$parent.mode)) {
        view.$forceUpdate();
        // view.loading = false;
      } else if (view.$parent.mode == "daily") {
        view.loadMDids();
      } else {
        // view.loading = false;
      }
    },
    loadMonthlyStatementFromMD: function (storeId) {
      let view = this;
      let promise = new Promise(function (resolve, reject) {
        let params = {
          storeId: storeId,
          date: view.selectedDate.format("YYYY-MM-DD"),
        };
        view.$helpers
          .GetCall("/api/sec/proxy/md/monthlyfinancialstatements", params)
          .then((response) => {
            let tmpObjectForSet = view.monthlyStatements[storeId];
            view.statementResponses.push(response.data);
            let statement = response.data;
            tmpObjectForSet.loading = false;
            tmpObjectForSet.confirmed = statement.length > 0 ? true : false;
            for (let k = 0; k < statement.length; k++) {
              if (!statement[k].confirmed) {
                tmpObjectForSet.confirmed = false;
              }
            }
            view.$set(view.monthlyStatements, storeId, tmpObjectForSet);

            resolve("");
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
      return promise;
    },
    loadPrevMonthlyStatementFromMD: function (storeId) {
      let view = this;

      let promise = new Promise(function (resolve, reject) {
        let params = {
          storeId: storeId,
          date: view.selectedDate.clone().subtract(1, 'months').format("YYYY-MM-DD"),
        };
        view.$helpers
          .GetCall("/api/sec/proxy/md/monthlyfinancialstatements", params)
          .then((response) => {
            view.prevMonthStatementResponses.push(response.data);
            resolve("");
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
      return promise;
    },
    loadStatisticsDynamicActualShifts: function (storeId) {
      let view = this;
      let promise = new Promise(function (resolve, reject) {
        let params = {
          storeId: storeId,
          date: view.selectedDate.format("YYYY-MM-DD"),
        };
        view.$helpers
          .GetCall("/api/sec/proxy/md/statisticdynamic/actualshifts", params)
          .then((response) => {
            Object.values(response.data).forEach(actualshift => {
              if (actualshift.count >= 1) {
                if (!(actualshift.remoteId in view.employeeWorkDaysCount)) {
                  view.employeeWorkDaysCount[actualshift.remoteId] = 0;
                }
                view.employeeWorkDaysCount[actualshift.remoteId] += 1;
              }
            });
            resolve("");
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
      return promise;
    },

    loadMDids: function () {
      let view = this;
      view.employeeMdids = {};
      view.$helpers
        .GetCall("/api/sec/employee/mdids", {})
        .then((response) => {
          for (let i in response.data) {
            let d = response.data[i];
            view.employeeMdids[d.mdid] = d.employeeId; // The mapping from Employee-MD-id to Employee-remote-id is n:1 => therefore no array of remote ids is needed
          }
          view.loadAllEmployeePreferences();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async loadAllEmployeePreferences() {
      let view = this;
      view.preferencePerEmployee = {};
      await this.loadEmployeePreferencesFromMD();
      for (let i in view.preferencePerEmployee) {
        view.preferencePerEmployee[i].sort(function (a, b) {
          if (a.timestamp < b.timestamp) return -1;
          if (a.timestamp > b.timestamp) return 1;
          return 0;
        });
      }

      //vanessa wants to have the preferences grouped to few lines
      for (let i in view.preferencePerEmployee) {
        let preferences = view.preferencePerEmployee[i];
        for (let j = 0; j < preferences.length; j++) {
          preferences[j].canMerge = false;
          if (preferences[j + 1]) {
            if (preferences[j].p.type == preferences[j + 1].p.type) {
              let end = view.$helpers.getMomentFromString(preferences[j].p.to);
              let begin = view.$helpers.getMomentFromString(preferences[j + 1].p.from);
              if (preferences[j].enumKey == preferences[j + 1].enumKey) {
                if (end.isSame(begin)) {
                  preferences[j].canMerge = true;
                }
                if (preferences[j].p.id == preferences[j + 1].p.id) {
                  preferences[j].canMerge = true;
                }
              }
            }
          }
        }
      }

      view.$forceUpdate();
      // view.loading = false;
    },

    loadEmployeePreferencesFromMD: function (soreId) {
      let view = this;
      let promise = new Promise(function (resolve, reject) {
        let params = {
          date: view.selectedDate.format("YYYY-MM-DD"),
        };
        view.$helpers
          .GetCall("/api/sec/proxy/md/employeepreferences", params)
          .then((response) => {
            for (let i in response.data) {
              let preference = response.data[i];
              if (preference.state != 1) continue; // Only Absences, we don't want to export wishes
              if (!preference.approved) continue; // Only export approved absences
              if (preference.remoteId == 0) continue; // Skip dummy
              view.CalculatePreference(preference);
            }

            resolve("");
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
      return promise;
    },

    GetContractsOverlappingMonth(contracts, monthFrom, monthTo) {
      let view = this;
      let result = [];
      contracts.forEach((contract) => {
        let contractFrom = view.$helpers.getMomentFromStringTZ(contract.from);
        if (contractFrom < monthFrom) contractFrom = monthFrom.clone();

        let contractTo = contract.to == null ? monthTo : view.$helpers.getMomentFromStringTZ(contract.to);
        if (contractTo > monthTo) contractTo = monthTo.clone();

        if (contractFrom < monthTo && contractTo > monthFrom) {
          result.push(contract);
        }
      });
      return result;
    },
    CalculatePreference: function (pref) {
      let view = this;
      let mainContract = null;
      let currentMonthFromTZ = this.$helpers.getMomentFromValuesTZ(view.selectedDate.year(), view.selectedDate.month(), 1);
      let currentMonthToTZ = currentMonthFromTZ.clone().add(1, "months");

      if (view.mdEmployeeContractMap[pref.employeeId]) {
        let contracts = view.mdEmployeeContractMap[pref.employeeId];
        let overlappingContracts = this.GetContractsOverlappingMonth(contracts, currentMonthFromTZ, currentMonthToTZ);
        overlappingContracts.forEach((contract) => {
          let contractFrom = view.$helpers.getMomentFromStringTZ(contract.from);
          if (!mainContract) {
            mainContract = contract;
          } else {
            let mainContractFrom = view.$helpers.getMomentFromStringTZ(mainContract.from);
            if (contractFrom > mainContractFrom) {
              mainContract = contract;
            }
          }
        });
      }

      let baseWorkValue = pref.workValue;
      let prefFrom = this.$helpers.getMomentFromStringTZ(pref.from);
      let prefTo = this.$helpers.getMomentFromStringTZ(pref.to);

      let prefStart = prefFrom.clone();
      let dayTimeRegular = mainContract != null ? Math.round(mainContract.weekTimeRegular / mainContract.weekWorkDaysRegular) : 9 * 60;
      let alwaysWork = pref.alwaysWork;
      while (prefStart < prefTo) {
        //step through day, and check interval
        let endOfDay = prefStart.clone().add(1, "days").startOf("day");
        let checkEnd = endOfDay < prefTo ? endOfDay : prefTo;
        let diff = checkEnd.diff(prefStart, "minutes");

        let prefObj = {
          p: pref,
          storeId: pref.storeId,
          remoteId: pref.remoteId,
          date: prefStart.format("YYYY-MM-DD"),
          workValue: 0,
          timestamp: prefStart.unix(),
        };

        let ratioFrom = prefStart < currentMonthFromTZ ? currentMonthFromTZ : prefStart > currentMonthToTZ ? currentMonthToTZ : prefStart;
        let ratioTo = checkEnd > currentMonthToTZ ? currentMonthToTZ : checkEnd < currentMonthFromTZ ? currentMonthFromTZ : checkEnd;
        let ratio = ratioTo.diff(ratioFrom) / checkEnd.diff(prefStart);

        let prefTime = Math.round(diff * ratio);
        if (prefTime > 0) {
          let isWeekend = !view.storeSettingWeekdaysMap[pref.storeId].includes(prefStart.isoWeekday());
          let tWorkValue = 0;
          if (baseWorkValue > -1) {
            let workValueRatio = ratioTo.diff(ratioFrom) / prefTo.diff(prefFrom);
            tWorkValue = Math.round(baseWorkValue * workValueRatio);
          } else {
            if (prefTime > dayTimeRegular) {
              tWorkValue += dayTimeRegular;
            } else {
              tWorkValue += prefTime;
            }
            //prefObj.workValue = tWorkValue;
          }
          if (pref.type == 1 || pref.type == 2 || pref.type == 3 || pref.type == 6 || pref.type == 7 || pref.type == 8) {
            if (!isWeekend || alwaysWork) {
              prefObj.workValue = tWorkValue;
            }
            if (pref.type == 1) {
              // Urlaub
              prefObj.enumKey = 52;
            } else if (pref.type == 2) {
              // Krankenstand
              prefObj.enumKey = 53;
            } else if (pref.type == 3) {
              // Feiertags Ausgleich
              prefObj.enumKey = 54;
            } else if (pref.type == 6) {
              // Pflegeurlaub
              prefObj.enumKey = 56;
            } else if (pref.type == 7) {
              // Ausbildung
              prefObj.enumKey = 57;
            } else if (pref.type == 8) {
              // Lohnfortzahlung
              prefObj.enumKey = 58;
              // add subtypes
              if (pref.subtype == 1) {
                // sonstiges?
                prefObj.subEnumKey = 62;
              } else if (pref.subtype == 8) {
                // Arbeitsunfall
                prefObj.subEnumKey = 64;
              } else if (pref.subtype == 9) {
                // Arztbesuch
                prefObj.subEnumKey = 63;
              } else if (pref.subtype == 10) {
                // Behörde
                prefObj.subEnumKey = 65;
              }
            }
            //}
          } else if (pref.type == 4 && alwaysWork) {
            prefObj.workValue = tWorkValue;
            prefObj.enumKey = 55;
          }
        }
        prefStart = prefStart.add(1, "days").startOf("day");

        if ((pref.type == 2 && prefObj.workValue >= 0) || prefObj.workValue > 0) {
          if (!view.preferencePerEmployee[pref.remoteId]) {
            view.preferencePerEmployee[pref.remoteId] = [];
          }
          view.preferencePerEmployee[pref.remoteId].push(prefObj);
        }
      }
    },

    checkDuplicateEmployees: function () {
      let view = this;
      view.duplicateEmployees = [];
      view.monthlyStatementPerEmployee = {};
      view.previousMonthlyStatementPerEmployee = {};
      let activeStores = [];
      view.stores.forEach(function (store) {
        if (view.globalFilter && view.globalFilter.stores && view.globalFilter.companyId == view.$store.state.companyId) {
          for (let i in view.globalFilter.stores) {
            if (view.globalFilter.stores[i].id == store.id && view.globalFilter.stores[i].selected) {
              activeStores.push(store.id);
            }
          }
        }
      });
      for (let i = 0; i < view.statementResponses.length; i++) {
        let statement = view.statementResponses[i];
        for (let k = 0; k < statement.length; k++) {
          if (statement[k].remoteId == null) continue;
          //if(statement[k].remoteId != 11263)continue;

          if (view.selectedStores[statement[k].storeId] && activeStores.includes(statement[k].storeId)) {
            if (view.monthlyStatementPerEmployee[statement[k].remoteId] == null || view.monthlyStatementPerEmployee[statement[k].remoteId] == undefined) {
              view.monthlyStatementPerEmployee[statement[k].remoteId] = [];
            } else {
              if (!view.duplicateEmployees.includes(statement[k].remoteId)) {
                view.duplicateEmployees.push(statement[k].remoteId);
              }
            }
            statement[k].workDaysCount = view.employeeWorkDaysCount[statement[k].remoteId] ?? 0;
            view.monthlyStatementPerEmployee[statement[k].remoteId].push(statement[k]);
          }
          view.$forceUpdate();
        }
      }
      for (let i = 0; i < view.prevMonthStatementResponses.length; i++) {
        let statement = view.prevMonthStatementResponses[i];
        for (let k = 0; k < statement.length; k++) {
          if (statement[k].remoteId == null) continue;

          if (view.selectedStores[statement[k].storeId] && activeStores.includes(statement[k].storeId)) {
            if (view.previousMonthlyStatementPerEmployee[statement[k].remoteId] == null || view.previousMonthlyStatementPerEmployee[statement[k].remoteId] == undefined) {
              view.previousMonthlyStatementPerEmployee[statement[k].remoteId] = [];
            } else {
              if (!view.duplicateEmployees.includes(statement[k].remoteId)) {
                view.duplicateEmployees.push(statement[k].remoteId);
              }
            }
            view.previousMonthlyStatementPerEmployee[statement[k].remoteId].push(statement[k]);
          }
          view.$forceUpdate();
        }
      }
    },

    buildExportTable: function () {
      let view = this;
      view.missingPersonalIds = [];
      view.buildingExportTable = true;
      view.exporting = true;
      view.somethingHasChangedWhatAffectsExportTable = false;
      view.exportTable = [];
      view.currentCSV = "";

      let activeStores = [];
      view.stores.forEach(function (store) {
        if (view.globalFilter && view.globalFilter.stores && view.globalFilter.companyId == view.$store.state.companyId) {
          for (let i in view.globalFilter.stores) {
            if (view.globalFilter.stores[i].id == store.id && view.globalFilter.stores[i].selected) {
              activeStores.push(store.id);
            }
          }
        }
      });

      let useBackendCalculation = true;
      if (useBackendCalculation) {
        // if (["monthly", "daily", "personnelProvisions"].includes(view.$parent.mode)) {
          // try to get export from backend
          let entity = {
            year: view.selectedDate.format("YYYY"),
            month: view.selectedDate.format("MM"),
            sessionId: view.sessionId,
            exportSettings: {
              name: "Standard",
              id: 0,
              selectedStores: Object.entries(this.selectedStores).filter(([storeId, active]) => active).map(([storeId, active]) => parseInt(storeId)),
              availableFields: Object.values(this.availableFields),
              availableFieldsAdditional: Object.values(this.availableFieldsAdditional),
              selectedWageTypes: this.content.filter((item) => item.checked).map((item) => item.id),
            },
          };
          view.getCsvExportFromBackend(entity)
          .then(result => {
            // view.$helpers.success("Erfolg", "Export wurde erfolgreich im Backend generiert.");
            var headers = Object.entries(view.availableFields).filter(([key, item]) => item.checked).map(([key, item]) => key);
            view.currentCSV = this.$helpers.b64DecodeUnicode(result.data.data.exportCsv); // Buffer.from(result.data.data.exportCsv, "base64").toString();
            console.log(view.currentCSV);
            var lines = view.currentCSV.split("\n");
            var hasHeader = this.availableFieldsAdditional.headerAdditional.item.key === 1;
            for(var i = hasHeader ? 1 : 0; i < lines.length; i++) {
              var data = lines[i].split(';');
              if (data.length < headers.length) {
                continue;
              }
              var obj = {};
              for(var j = 0; j < data.length-1; j++) {
                let trimmed = data[j].trim();
                if (["paidWorkTime", "dailyPayoutValue", "dailyPayoutHoursValue"].includes(headers[j].trim())
                    || (view.$parent.mode == "personnelProvisions" && !["externalCompanyId", "externalEmployeeId", "employeeName"].includes(headers[j].trim()))) {
                  trimmed = parseFloat(trimmed.replace(",", "."));
                }
                obj[headers[j].trim()] = trimmed;
              }
              view.exportTable.push(obj);
            }
            view.exportTable.sort((a, b) => {
              if (a.employeeName < b.employeeName) {
                return -1;
              }
              if (a.employeeName > b.employeeName) {
                return 1;
              }
              if (a.contentName < b.contentName) {
                return -1;
              }
              if (a.contentName > b.contentName) {
                return 1;
              }
              return 0;
            });
            // JSON.stringify(jsonObj);
            console.log(view.exportTable);
          })
          .catch(error => {
            view.$helpers.error("Fehler", "Export Vorschau konnte nicht erstellt werden.");
            console.log(error);
          })
          .finally(() => {
            view.buildingExportTable = false;
            view.exporting = false;
            view.$forceUpdate();
          });
        // }
        // else if (view.$parent.mode == "individual-wagetypes") {
        //   let promises = [];
        //   Object.entries(this.selectedStores).filter(([storeId, active]) => active).forEach(([storeId, active]) => {
        //     let entity = {
        //       year: view.selectedDate.format("YYYY"),
        //       month: view.selectedDate.format("MM"),
        //       sessionId: view.sessionId,
        //       storeId: storeId,
        //     };
        //     promises.push(view.$helpers.calculateIndividualWageTypeExport(entity));
        //   });

        //   Promise.all(promises)
        //   .then(responses => {
        //     console.log(responses);

        //     view.buildingExportTable = false;
        //     view.exporting = false;
        //     view.$forceUpdate();
        //   });
        // }
      }
      // else {
      //   let promise = new Promise(function (resolve, reject) {
      //   setTimeout(function () {
      //       // This setTimeout is needed, otherwise the CPU is too busy to update the frontend and won't show the loading animation
      //       view.somethingHasChangedWhatAffectsExportTable = false;
      //       view.exportTable = [];

      //       if (["monthly", "individual-wagetypes"].includes(view.$parent.mode)) {
      //         for (let i in view.monthlyStatementPerEmployee) {
      //           let statements = view.monthlyStatementPerEmployee[i];
      //           for (let k in statements) {
      //             let statement = statements[k];
      //             for (let l = 0; l < view.content.length; l++) {
      //               if (view.content[l].checked) {
      //                 let xy = {};
      //                 if (view.availableFields["externalCompanyId"].checked) {
      //                   xy.externalCompanyId = view.bmdId;
      //                 }
      //                 if (view.availableFields["employeeName"].checked) {
      //                   if (view.employeeMap[statement.remoteId]) {
      //                     xy.employeeName = view.employeeMap[statement.remoteId].lastName + " " + view.employeeMap[statement.remoteId].firstName;
      //                   } else {
      //                     xy.employeeName = "";
      //                   }
      //                 }

      //                 if (view.availableFields["month"].checked) {
      //                   if (view.availableFieldsAdditional["monthAdditional"].checked) {
      //                     if (view.availableFieldsAdditional["monthAdditional"].item.key === "current") {
      //                       xy.month = view.timeframeStart.months() + 1;
      //                     }
      //                     else if (view.availableFieldsAdditional["monthAdditional"].item.key === "previous") {
      //                       xy.month = ((view.timeframeStart.months() - 1) % 12) + 1;
      //                     }
      //                     else if (view.availableFieldsAdditional["monthAdditional"].item.key === "next") {
      //                       xy.month = ((view.timeframeStart.months() + 1) % 12) + 1;
      //                     }
      //                   } else {
      //                     // default: current month
      //                     xy.month = view.timeframeStart.months() + 1;
      //                   }
      //                 }

      //                 xy.lohnart = view.content[l].externalId;
      //                 if (view.availableFields["contentName"].checked) {
      //                   xy.contentName = view.content[l].name;
      //                 }

      //                 if (view.availableFields["externalEmployeeId"].checked) {
      //                   if (view.employeeMap[statement.remoteId]) {
      //                     xy.externalEmployeeId = view.employeeMap[statement.remoteId].mdPersonnelId;

      //                     if (view.employeeMap[statement.remoteId].mdPersonnelId == "" || view.employeeMap[statement.remoteId].mdPersonnelId == 0 || view.employeeMap[statement.remoteId].mdPersonnelId == null) {
      //                       view.missingPersonalIds.push(statement.remoteId);
      //                     }
      //                   } else {
      //                     xy.externalEmployeeId = 0;
      //                   }
      //                 }

      //                 // NOW HANDLE THE LAST THING - PUSH IT HERE OR DONT PUSH IT HERE
      //                 let key = view.content[l].key;
      //                 let realKey = view.content[l].key;

      //                 if (key.endsWith("OnlyPositive") || key.endsWith("OnlyNegative")) {
      //                   realKey = key.substring(0, realKey.length - 12);
      //                 }

      //                 let computedValue = 0;
      //                 if (view.content[l].useFunction) {
      //                   computedValue = view.computeValueByFunction(key, statement);
      //                 } else {
      //                   computedValue = statement[realKey];
      //                 }

      //                 if (key.endsWith("OnlyPositive")) {
      //                   if (computedValue < 0) {
      //                     continue;
      //                   }
      //                 }
      //                 if (key.endsWith("OnlyNegative")) {
      //                   if (computedValue > 0) {
      //                     continue;
      //                   }
      //                 }

      //                 xy.paidWorkTime = 0;
      //                 if (view.content[l].toHours) {
      //                   xy.paidWorkTime = Math.round((computedValue / 60) * 100) / 100;
      //                 } else if (view.content[l].toDays) {
      //                   xy.paidWorkTime = Math.round((computedValue / 24 / 60) * 100) / 100;
      //                 } else if (view.content[l].useComputed) {
      //                   xy.paidWorkTime = computedValue;
      //                 }

      //                 // check all-in employees
      //                 if (view.employeeMap[statement.remoteId] && view.employeeMap[statement.remoteId].allIn
      //                   && (key.startsWith("paid") || key === "moretimeBonus25OnlyPositive" || key === "overtimeBonus50OnlyPositive")) {
      //                     xy.paidWorkTime = 0;
      //                 } else if ((!view.employeeMap[statement.remoteId] || !view.employeeMap[statement.remoteId].allIn)
      //                   && key === "allInHoursOnlyPositive") {
      //                     xy.paidWorkTime = 0;
      //                 }

      //                 if (view.availableFieldsAdditional["paidWorkTimeAdditional"].checked) {
      //                   if (view.availableFieldsAdditional["paidWorkTimeAdditional"].item.key === 1
      //                       && xy.paidWorkTime === 0) {
      //                     continue;
      //                   }
      //                 }
      //                 view.exportTable.push(xy); // PUSH !
      //               }
      //             }
      //           }
      //         }
      //       }
      //       else if (view.$parent.mode == "daily") {

      //         let start = view.timeframeStart;
      //         let end = view.timeframeEnd;

      //         let activeFromTo = [];
      //         view.mdContractArray
      //         .filter(c => (!(view.$helpers.getMomentFromStringTZ(c.from) <= start && c.to && view.$helpers.getMomentFromStringTZ(c.to) <= start)
      //             && !(view.$helpers.getMomentFromStringTZ(c.from) > end) && activeStores.includes(c.storeId)))
      //         .forEach(c => {
      //           let fromMoment = view.$helpers.getMomentFromStringTZ(c.from);
      //           let toMoment = c.to ? view.$helpers.getMomentFromStringTZ(c.to) : null;

      //           let from = fromMoment <= start ? start : fromMoment;
      //           let to = (toMoment && toMoment <= end) ? toMoment.subtract(1, 'day') : end;

      //           if (!(activeFromTo.find(aft => aft.remoteId === c.remoteId && aft.from === from && aft.to === to))) {
      //             activeFromTo.push({remoteId: c.remoteId, from: from, to: to});
      //           }
      //         });

      //         if (view.availableFieldsAdditional["dailyProcessingFlagAdditional"].checked
      //             && view.availableFields["dailyProcessingFlag"].checked
      //             && view.availableFieldsAdditional["dailyProcessingFlagAdditional"].item.key === 0) {

      //           view.content.forEach(field => {
      //             if (field.checked) {
      //               activeFromTo.forEach(aft => {
      //                 let emp = Object.values(view.employeeMap).find(emp => emp.id === aft.remoteId);

      //                 // TODO: add skip criteria here
      //                 // maybe no bmdId, mdPersonnelId, no active contract, etc?
      //                 if (!emp || !activeStores.includes(emp.storeId) || emp.isHidden || emp.external || !emp.mdPersonnelId || emp.mdPersonnelId == 0 || emp.mdPersonnelId.length === 0) {
      //                   return;
      //                 }

      //                 let xy = {};
      //                 if (view.availableFields["dailyExternalCompanyId"].checked) {
      //                   xy.dailyExternalCompanyId = view.bmdId;
      //                 }
      //                 if (view.availableFields["dailyExternalEmployeeId"].checked) {
      //                   if (emp.mdPersonnelId) {
      //                     xy.dailyExternalEmployeeId = emp.mdPersonnelId;
      //                   } else {
      //                     xy.dailyExternalEmployeeId = 0;
      //                   }
      //                 }
      //                 if (view.availableFields["dailyEmployeeName"].checked) {
      //                   xy.dailyEmployeeName = emp.lastName + " " + emp.firstName;
      //                 }

      //                 if (view.availableFields["dailyLohnart"].checked) {
      //                   xy.dailyLohnart = field.externalId;
      //                 }
      //                 if (view.availableFields["dailyContentName"].checked) {
      //                   xy.dailyContentName = field.name;
      //                 }

      //                 if (view.availableFields["dailyFrom"].checked) {
      //                   xy.dailyFrom = aft.from.format("DD.MM.YYYY");
      //                 }
      //                 if (view.availableFields["dailyTo"].checked) {
      //                   xy.dailyTo = aft.to.format("DD.MM.YYYY");
      //                 }
      //                 if (view.availableFields["dailyPayoutValue"].checked) {
      //                   xy.dailyPayoutValue = 0;
      //                 }
      //                 if (view.availableFields["dailyPayoutHoursValue"].checked) {
      //                   xy.dailyPayoutHoursValue = 0;
      //                 }
      //                 xy.dailyProcessingFlag = 1; // Löschen

      //                 view.exportTable.push(xy);
      //               });
      //             }
      //           });
      //         }

      //         for (let i in view.preferencePerEmployee) {
      //           let preferences = view.preferencePerEmployee[i];
      //           let startDate = null;
      //           let kumWorkValue = 0;
      //           let kumWorkDays = 0;
      //           for (let k = 0; k < preferences.length; k++) {
      //             let preference = preferences[k];
      //             //if (preference.remoteId != 4167) continue;

      //             if (preference.canMerge && preferences[k + 1] != undefined) {
      //               if (startDate == null) {
      //                 startDate = preference.date;
      //               }
      //               kumWorkValue += preference.workValue;
      //               kumWorkDays += 1;
      //             } else
      //             {
      //               if (view.selectedStores[preference.storeId] && activeStores.includes(preference.storeId)) {
      //                 for (let l = 0; l < view.content.length; l++) {
      //                   if (view.content[l].checked && (preference.enumKey == view.content[l].enumKey || preference.subEnumKey == view.content[l].enumKey)) {
      //                     let xy = {};
      //                     xy.pref = preference;
      //                     if (preference.subEnumKey == view.content[l].enumKey) {
      //                       // this is a subtype. check if the pref was already added with supertype. if so, remove it and add the subtype
      //                       let index = view.exportTable.findIndex(e => e.pref == preference);
      //                       if (index > -1) {
      //                         view.exportTable.splice(index, 1);
      //                       }
      //                     } else if (preference.enumKey == view.content[l].enumKey) {
      //                       // this is a supertype. check if the pref was already added with subtype. if so, skip this one
      //                       let index = view.exportTable.findIndex(e => e.pref == preference);
      //                       if (index > -1) {
      //                         continue;
      //                       }
      //                     }
      //                     if (view.availableFields["dailyExternalCompanyId"].checked) {
      //                       xy.dailyExternalCompanyId = view.bmdId;
      //                     }
      //                     if (view.availableFields["dailyExternalEmployeeId"].checked) {
      //                       if (view.employeeMap[preference.remoteId]) {
      //                         if (view.employeeMap[preference.remoteId].mdPersonnelId == "" || view.employeeMap[preference.remoteId].mdPersonnelId == 0 || view.employeeMap[preference.remoteId].mdPersonnelId == null) {
      //                           view.missingPersonalIds.push(preference.remoteId);
      //                         }
      //                         xy.dailyExternalEmployeeId = view.employeeMap[preference.remoteId].mdPersonnelId;
      //                       } else {
      //                         xy.dailyExternalEmployeeId = 0;
      //                       }
      //                     }
      //                     if (view.availableFields["dailyEmployeeName"].checked) {
      //                       if (view.employeeMap[preference.remoteId]) {
      //                         xy.dailyEmployeeName = view.employeeMap[preference.remoteId].lastName + " " + view.employeeMap[preference.remoteId].firstName;
      //                       } else {
      //                         xy.dailyEmployeeName = "";
      //                         if (preference.p && preference.p.employeeId) {
      //                           xy.dailyEmployeeName = preference.p.employeeId;
      //                         }
      //                       }
      //                     }

      //                     if (view.availableFields["dailyLohnart"].checked) {
      //                       xy.dailyLohnart = view.content[l].externalId;
      //                     }
      //                     if (view.availableFields["dailyContentName"].checked) {
      //                       xy.dailyContentName = view.content[l].name;
      //                     }

      //                     let startDateForBMD = view.$helpers.getMomentFromString(startDate == null ? preference.date : startDate);
      //                     let endDateForBMD = view.$helpers.getMomentFromString(preference.date);

      //                     xy.dailyFrom = startDateForBMD.format("DD.MM.YYYY");
      //                     xy.dailyTo = endDateForBMD.format("DD.MM.YYYY");

      //                     // work value is minutes
      //                     let computedValue = preference.workValue + kumWorkValue;
      //                     xy.dailyPayoutValue = 0;
      //                     // new setting is overwriting the regular "toHours" stuff
      //                     if (view.availableFieldsAdditional["dailyPayoutValueAdditional"].checked) {
      //                       if (view.availableFieldsAdditional["dailyPayoutValueAdditional"].item.key === 0) {
      //                         xy.dailyPayoutValue = Math.round((computedValue / 60) * 100) / 100;
      //                       } else {
      //                         xy.dailyPayoutValue = kumWorkDays + 1;
      //                       }
      //                     } else {
      //                       // previous implementation - still use this when the setting is disabled
      //                       if (view.content[l].toHours) {
      //                         xy.dailyPayoutValue = Math.round((computedValue / 60) * 100) / 100;
      //                       }
      //                     }

      //                     xy.dailyPayoutHoursValue = 0;
      //                     // new setting is overwriting the regular "toHours" stuff
      //                     if (view.availableFieldsAdditional["dailyPayoutHoursValueAdditional"].checked) {
      //                       if (view.availableFieldsAdditional["dailyPayoutHoursValueAdditional"].item.key === 0) {
      //                         xy.dailyPayoutHoursValue = Math.round((computedValue / 60) * 100) / 100;
      //                       } else {
      //                         xy.dailyPayoutHoursValue = kumWorkDays + 1;
      //                       }
      //                     } else {
      //                       // previous implementation - still use this when the setting is disabled
      //                       if (view.content[l].toHours) {
      //                         xy.dailyPayoutHoursValue = Math.round((computedValue / 60) * 100) / 100;
      //                       }
      //                     }

      //                     if (view.availableFieldsAdditional["dailyProcessingFlagAdditional"].checked
      //                         && view.availableFields["dailyProcessingFlag"].checked
      //                         && view.availableFieldsAdditional["dailyProcessingFlagAdditional"].item.key === 0) {
      //                       xy.dailyProcessingFlag = 2; // Neu anlegen/ändern
      //                     } else {
      //                       xy.dailyProcessingFlag = 3; // Neu anlegen/Zusammenhängen
      //                     }

      //                     view.exportTable.push(xy);
      //                   }
      //                 }
      //               }
      //               startDate = null;
      //               kumWorkValue = 0;
      //               kumWorkDays = 0;
      //             }
      //           }
      //         }

      //         view.exportTable.sort((a,b) => {
      //           // MA -> Start Datum -> End Datum -> Lohnart
      //           // Pro Mitarbeiter alles Löschen, dann erst neu anlegen, falls sich neue Abwesenheiten mit alten überschneiden würden
      //           if (a.dailyEmployeeName < b.dailyEmployeeName) {
      //             return -1;
      //           }
      //           if (a.dailyEmployeeName > b.dailyEmployeeName) {
      //             return 1;
      //           }
      //           if (a.dailyLohnart < b.dailyLohnart) {
      //             return -1;
      //           }
      //           if (a.dailyLohnart > b.dailyLohnart) {
      //             return 1;
      //           }
      //           if (a.dailyProcessingFlag < b.dailyProcessingFlag) {
      //             return -1;
      //           }
      //           if (a.dailyProcessingFlag > b.dailyProcessingFlag) {
      //             return 1;
      //           }
      //           if (a.dailyFrom < b.dailyFrom) {
      //             return -1;
      //           }
      //           if (a.dailyFrom > b.dailyFrom) {
      //             return 1;
      //           }
      //           if (a.dailyTo < b.dailyTo) {
      //             return -1;
      //           }
      //           if (a.dailyTo > b.dailyTo) {
      //             return 1;
      //           }
      //           if (a.dailyContentName < b.dailyContentName) {
      //             return -1;
      //           }
      //           if (a.dailyContentName > b.dailyContentName) {
      //             return 1;
      //           }
      //           return 0;
      //         });
      //       }
      //       else {
      //         for (let i in view.monthlyStatementPerEmployee) {
      //           let statements = view.monthlyStatementPerEmployee[i];
      //           for (let k in statements) {
      //             let statement = statements[k];

      //             let xy = {};
      //             if (view.availableFields["externalCompanyId"].checked) {
      //               xy.externalCompanyId = view.bmdId;
      //             }

      //             if (view.availableFields["externalEmployeeId"].checked) {
      //               if (view.employeeMap[statement.remoteId]) {
      //                 xy.externalEmployeeId = view.employeeMap[statement.remoteId].mdPersonnelId;

      //                 if (view.employeeMap[statement.remoteId].mdPersonnelId == "" || view.employeeMap[statement.remoteId].mdPersonnelId == 0 || view.employeeMap[statement.remoteId].mdPersonnelId == null) {
      //                   view.missingPersonalIds.push(statement.remoteId);
      //                 }
      //               } else {
      //                 xy.externalEmployeeId = 0;
      //               }
      //             }

      //             if (view.availableFields["employeeName"].checked) {
      //               if (view.employeeMap[statement.remoteId]) {
      //                 xy.employeeName = view.employeeMap[statement.remoteId].lastName + " " + view.employeeMap[statement.remoteId].firstName;
      //               } else {
      //                 xy.employeeName = "";
      //               }
      //             }

      //             Object.values(view.availableFields).filter(item => item.key.startsWith("balance")).forEach(item => {
      //               // balance from MD is in minutes and will be our default
      //               xy[item.key] = statement[view.content[item.item.key].key];
      //               // check if we need to convert the value
      //               if (view.availableFieldsAdditional["valuesAdditional"].checked) {
      //                 switch (view.availableFieldsAdditional["valuesAdditional"].item.key) {
      //                   case 1: // hours
      //                     xy[item.key] = Math.round((xy[item.key] / 60) * 100) / 100;
      //                     break;
      //                   case 2: // industry minutes
      //                     xy[item.key] = Math.round((xy[item.key] * 100 / 60) * 100) / 100;
      //                     break;
      //                   default: break;
      //                 }
      //               }

      //               // check if we need to multiply the value
      //               if (view.availableFieldsAdditional[item.key + "Additional"].checked) {
      //                 switch (view.availableFieldsAdditional[item.key + "Additional"].item.key) {
      //                   case 1: // multiply by 1.25
      //                     xy[item.key] = Math.round((xy[item.key] * 1.25) * 100) / 100;
      //                     break;
      //                   case 2: // multiply by 1.5
      //                     xy[item.key] = Math.round((xy[item.key] * 1.5) * 100) / 100;
      //                     break;
      //                   case 3: // multiply by 1.75
      //                     xy[item.key] = Math.round((xy[item.key] * 1.75) * 100) / 100;
      //                     break;
      //                   case 4: // multiply by 2
      //                     xy[item.key] = Math.round((xy[item.key] * 2) * 100) / 100;
      //                     break;
      //                   default: // no multiplication
      //                     break;
      //                 }
      //               }
      //             });

      //             view.exportTable.push(xy); // PUSH !
      //           }
      //         }
      //       }

      //       let tmpUniq = view.missingPersonalIds;
      //       view.missingPersonalIds = [...new Set(tmpUniq)];

      //       view.buildingExportTable = false;
      //       view.exporting = false;
      //       view.$forceUpdate();
      //       resolve("");
      //     }, 250);
      //   });
      //   return promise;
      // }
      return Promise.resolve();
    },

    // computeValueByFunction(key, statement) {
    //   if (key === "overtimeBonus50OnlyPositive") {
    //     // let unmodifiedWorkTimeBalance = statement["workTimeBalance"] * 1.0 + statement["paidWorkTime"] * 1.0;
    //     // let unmodifiedMoreTimeBalance = statement["moreTimeBalance"] * 1.0 + statement["paidMoreTime"] * 1.0;
    //     // return Math.max(0, unmodifiedWorkTimeBalance - Math.max(0, unmodifiedMoreTimeBalance));

    //     let positivePaidWorkTime = Math.max(0, statement["paidWorkTime"]);
    //     let positivePaidMoreTime = Math.max(0, statement["paidMoreTime"]);
    //     return Math.max(0, positivePaidWorkTime - positivePaidMoreTime);

    //   } else if (key === "moretimeBonus25OnlyPositive") {
    //     // let unmodifiedWorkTimeBalance = statement["workTimeBalance"] * 1.0 + statement["paidWorkTime"] * 1.0;
    //     // let unmodifiedMoreTimeBalance = statement["moreTimeBalance"] * 1.0 + statement["paidMoreTime"] * 1.0;
    //     // return Math.max(0, Math.min(unmodifiedWorkTimeBalance, Math.max(0, unmodifiedMoreTimeBalance)));

    //     let positivePaidWorkTime = Math.max(0, statement["paidWorkTime"]);
    //     let positivePaidMoreTime = Math.max(0, statement["paidMoreTime"]);
    //     return Math.max(0, Math.min(positivePaidWorkTime, positivePaidMoreTime));
    //   } else if (key === "allInHoursOnlyPositive") {
    //     // all-in hours should be all hours worked over the regular hours
    //     return Math.max(0, statement["paidWorkTime"]);
    //   }
    //   return 0;
    // },

    loadEmployees: function (force = false, employeeIdToSelect = null) {
      let view = this;

      this.$helpers.GetEmployeeMap(force, false)
        .then((response) => {
          Object.values(response).forEach(resp => {
            view.employeeMap[resp.id] = resp;
          });

          // view.mdEmployeeContractMap = {};
          // view.mdContractArray = [];
          // view.getEmployeeContracts().then((response) => {
          //   for (let i in response.data) {
          //     let contract = response.data[i];
          //     if (!view.mdEmployeeContractMap[contract.employeeId]) {
          //       view.mdEmployeeContractMap[contract.employeeId] = [];
          //     }
          //     view.mdEmployeeContractMap[contract.employeeId].push(contract);
          //     view.mdContractArray.push(contract);
          //   }
          // });
          /*
        this.employeeArray = empls;
        this.prepareStoreEmployees(empls, employeeIdToSelect, force);
        this.loadingStage++;
        */
        });
    },

    loadStoresForCompany: function () {
      let view = this;
      view.loadStores = true;
      let params = {};

      return view.$helpers.GetExportDeselectedStores().then((deselectedStores) => {
        return view.$helpers
          .GetCall("/api/sec/stores", params)
          .then((response) => {
            view.stores = response.data.data;

            view.storesMap = {};
            for (let j = 0; j < view.stores.length; j++) {
              let tmp = view.stores[j];
              let obj = {
                id: tmp.id,
                name: tmp.name,
                description: tmp.description,
                selected: deselectedStores[tmp.id] != undefined && deselectedStores[tmp.id] != null ? false : true,
                monthlyFinancialStatement: 0,
              };
              view.$set(view.selectedStores, tmp.id, deselectedStores[tmp.id] != undefined && deselectedStores[tmp.id] != null ? false : true);
              view.storesMap[tmp.id] = obj;
              view.monthlyStatements[tmp.id] = {
                confirmed: false,
                loading: true,
              };
              /*
              if(view.globalFilter && view.globalFilter.stores && view.globalFilter.companyId == view.$store.state.companyId){
                for(let i in view.globalFilter.stores){
                  if(view.globalFilter.stores[i].id == tmp.id && view.globalFilter.stores[i].selected){
                    pushed = true;
                  }
                }
              }
              if(!pushed){
                view.storesMap[tmp.id].selected = false;
              }
              */
            }

            view.loadStores = false;

            // view.loadAllMonthlyStatements();
            // this.loading = false;

            view.$forceUpdate();
          })
          .catch((e) => {
            view.loadStores = false;
            // view.loading = false;
            console.log(e);
          });
      });
    },

    modDate: function (method) {
      let view = this;

      if (method == "add") {
        this.selectedDate.add(1, "months");
        this.timeframeStart = this.selectedDate.clone().startOf("month");
        this.timeframeEnd = this.selectedDate.clone().endOf("month");
        this.$forceUpdate();
      } else if (method == "sub") {
        this.selectedDate.subtract(1, "months");
        this.timeframeStart = this.selectedDate.clone().startOf("month");
        this.timeframeEnd = this.selectedDate.clone().endOf("month");
        this.$forceUpdate();
      }
      this.$helpers.SetExportSelectedDate({ currentDate: this.$helpers.getMomentTZ(), date: this.selectedDate, });
      this.refresh(false, 5);
    },

    GetEmployeeInfoTooltip() {
      let view = this;
      return {
        components: [
          {
            type: "listlabel",
            state: {
              labels:
                this.duplicateEmployees.length > 0
                  ? this.duplicateEmployees.map((eId) => {
                      return {
                        text: `${view.employeeMap[eId] ? view.employeeMap[eId].firstName + " " + view.employeeMap[eId].lastName : "Unbekannt Unbekannt"}`,
                        styleLabel: {
                          boxShadow: "0 2px 0px -1px var(--contrast-2)",
                        },
                      };
                    })
                  : [{ text: "-" }],
            },
          },
        ],
        allowWindowOverflow: true,
      };
    },
    GetEmployeeMissingPersoTooltip() {
      let view = this;
      return {
        components: [
          {
            type: "listlabel",
            state: {
              labels:
                this.missingPersonalIds.length > 0
                  ? this.missingPersonalIds.map((eId) => {
                      return {
                        text: `${view.employeeMap[eId] ? view.employeeMap[eId].firstName + " " + view.employeeMap[eId].lastName : "Unbekannt Unbekannt"}`,
                        styleLabel: {
                          boxShadow: "0 2px 0px -1px var(--contrast-2)",
                        },
                      };
                    })
                  : [{ text: "-" }],
            },
          },
        ],
        allowWindowOverflow: true,
      };
    },

    toggleStoreLocalStorage(storeId, selected) {
      let view = this;
      view.$helpers.GetExportDeselectedStores().then((data) => {
        data[storeId] = selected;
        if (selected) {
          delete data[storeId];
        }
        view.$helpers.SetExportDeselectedStores(data);
      });
    },

    globalFilterChanged() {
      this.somethingHasChangedWhatAffectsExportTable = true;
      this.checkDuplicateEmployees();
    },

    saveContentLS() {
      let saveData = {};
      for (let i in this.content) {
        saveData[this.content[i].enumKey] = {
          enumKey: this.content[i].enumKey,
          checked: this.content[i].checked,
        };
      }
      if (["monthly"].includes(this.$parent.mode)) {
        this.$helpers.SetExportSelectedWagetypesMonthly(saveData);
      } else if (this.$parent.mode == "individual-wagetypes") {
        this.$helpers.SetExportSelectedWagetypesIndividualWagetypes(saveData);
      } else if (this.$parent.mode == "daily") {
        this.$helpers.SetExportSelectedWagetypesDaily(saveData);
      }
    },
    saveAvailableFieldsLS() {
      let saveData = {};
      for (let i in this.availableFields) {
        saveData[this.availableFields[i].key] = {
          key: this.availableFields[i].key,
          checked: this.availableFields[i].checked,
        };
        if (this.$parent.mode === "personnelProvisions") {
          saveData[this.availableFields[i].key].newName = this.availableFields[i].item ? this.availableFields[i].item.label : this.availableFields[i].newName;
          saveData[this.availableFields[i].key].item = this.availableFields[i].item;
        } else {
          saveData[this.availableFields[i].key].newName = this.availableFields[i].newName;
        }
      }
      for (let i in this.availableFieldsAdditional) {
        saveData[this.availableFieldsAdditional[i].key] = {
          key: this.availableFieldsAdditional[i].key,
          checked: this.availableFieldsAdditional[i].checked,
          item: this.availableFieldsAdditional[i].item,
        };
      }
      this.$helpers.SetExportSelectedColumns(saveData);
    },

    OpenFilterDropdown($event) {
      var view = this;
      var target = $event.currentTarget;
      this.LoadFilterDropdown();

      this.$helpers.OpenPopup({
        elRelative: target,
        type: "list",
        componentState: {
          entries: view.filterDropdownOptions,
        },
        remainAfterSelect: true,
        pointerDirection: 0,
        iconsLeft: true,
        selectCallback: function ($event, item, dropdown) {
          item.toggled = !item.toggled;
          if (item.type == "1") {
            view.hiddenStates.exportsettings = !view.hiddenStates.exportsettings;
          }
          if (item.type == "2") {
            view.hiddenStates.employmentModels = !view.hiddenStates.employmentModels;
          }
          if (item.type == "3") {
            view.hiddenStates.wageTypes = !view.hiddenStates.wageTypes;
          }
          if (item.type == "4") {
            view.hiddenStates.employees = !view.hiddenStates.employees;
          }
          if (item.type == "5") {
            view.hiddenStates.preview = !view.hiddenStates.preview;
          }

          /*
          if (item.state) item.state.show = item.toggled;
          if (item.type) item.type.show = item.toggled;
          if (item.all) {
            view.filterDropdownOptions.forEach((option) => {
              if (option.state || option.type) {
                option.toggled = item.toggled;
                if (option.state) option.state.show = item.toggled;
                if (option.type) option.type.show = item.toggled;
              }
            });
          }*/
        },
      });
    },
    LoadFilterDropdown() {
      var view = this;
      this.filterDropdownOptions = [];
      view.filterDropdownOptions.push({
        label: "Exporteinstellungen",
        toggled: !view.hiddenStates.exportsettings,
        type: "1",
        color: "#000",
      });
      if (this.$parent.mode === "individual-wagetypes") {
        view.filterDropdownOptions.push({
          label: "Verfügbare Beschäftigungsmodelle",
          toggled: !view.hiddenStates.employmentModels,
          type: "2",
          color: "#000",
        });
      }
      if (this.$parent.mode !== "personnelProvisions") {
        view.filterDropdownOptions.push({
          label: "Verfügbare Lohnarten",
          toggled: !view.hiddenStates.wageTypes,
          type: "3",
          color: "#000",
        });
      }
      if (["monthly"].includes(view.$parent.mode)) {
        view.filterDropdownOptions.push({
          label: "MD Monatsabschlüsse",
          toggled: !view.hiddenStates.employees,
          type: "4",
          color: "#000",
        });
      }
      view.filterDropdownOptions.push({
        label: "Export Vorschau",
        toggled: !view.hiddenStates.preview,
        type: "5",
        color: "#000",
      });
      /*
      this.filterDropdownOptions.push({
        label: "Alle",
        toggled: true,
        all: true,
        color: "#eeeeee",
      });*/
    },

    OpenMenuDetails($event, state){
      let view = this;

      this.$helpers.OpenPopup({
        type: 'burgermenu',
        componentState: state,
        listStyle: true,
        noScroll: true,
        pointerDirection: 0,
        elRelative: $event.currentTarget,
        pointerOffset: -15,
      });
    },

    previewPdf(doc, filename){
      var data = doc.output('datauristring');
      this.modalPdfFilename = filename;
      this.modalPdf = data;
      this.modalPdfDoc = doc;

      // console.log(filename);
      // console.log(data);
      // console.log(doc);

      this.showModal('modal-pdfpreview');
    },
    closePdfPreview(){
      this.hideModal('modal-pdfpreview');
      console.log('cleaning up after pdf preview close');
      this.modalPdf = '';
    },
    hideModal(ref) {
      this.$refs[ref].hide()
    },
    showModal(ref) {
      this.$refs[ref].show()
    },
    savePreviewedPdf(){
      this.modalPdfDoc.save(this.modalPdfFilename);
    },
    openPreviewedPdf(){
      this.modalPdfDoc.output('dataurlnewwindow');
      window.open(this.modalPdfDoc.output("bloburl"), "_blank");
    },
    addPdfReportHeader(headers, data, fieldKey, fieldName, sums, sumsPositive, sumsNegative){
      if(data[fieldKey]){
        headers.push(fieldName)
        this.resetSums(fieldKey, sums, sumsPositive, sumsNegative);
      }
    },
    resetSums(fieldKey, sums, sumsPositive, sumsNegative)  {
      sums[fieldKey] = 0;
      sumsPositive[fieldKey] = 0;
      sumsNegative[fieldKey] = 0;
    },
    addPdfReportColumnValue(row, data, fieldKey, balance, sums, sumsPositive, sumsNegative, totalSums, totalSumsPositive, totalSumsNegative, divisor, isNumerical){
      if(data[fieldKey]){
        let val = balance[fieldKey];
        if(isNumerical && val != null){
          row.push((val/divisor).toFixed(2));
          sums[fieldKey] += val;
          totalSums[fieldKey] += val;
          val >= 0 ? sumsPositive[fieldKey] += val : sumsNegative[fieldKey] += val;
          val >= 0 ? totalSumsPositive[fieldKey] += val : totalSumsNegative[fieldKey] += val;
        } else {
          row.push( val != null ? val : '-');
        }
      }
    },
    addPdfReportSumValue(sumRow, positiveRow, negativeRow, data, fieldKey, sums, sumsPositive, sumsNegative, divisor, isNumerical){
      if(data[fieldKey]){
        let sumVal = sums[fieldKey];
        let posVal = sumsPositive[fieldKey];
        let negVal = sumsNegative[fieldKey];
        if(isNumerical){
          sumRow.push((sumVal/divisor).toFixed(2));
          positiveRow.push((posVal/divisor).toFixed(2));
          negativeRow.push((negVal/divisor).toFixed(2));
        } else {
          sumRow.push('-');
          positiveRow.push('-');
          negativeRow.push('-');
        }
      }
    },

    generateWorkTimeOverviewPdfReport(){
      let view = this;
      let data = {
        "balances": []
      };

      let fields = [
        { "fieldKey": "prevMonthTime", "fieldName": "Saldo +/- VM"},
        { "fieldKey": "prevMonthOverTime", "fieldName": "gesetzl. ÜST VM"},
        { "fieldKey": "prevMonthHolidayTime", "fieldName": "FT-h VM"},

        { "fieldKey": "workTimeRegular", "fieldName": "Soll h akt. M"},
        { "fieldKey": "totalWorkTime", "fieldName": "Ist h akt. M"},
        { "fieldKey": "holidayTime", "fieldName": "FT-h akt. M."},
        { "fieldKey": "holidayAdjustmentWorkTime", "fieldName": "FTA-h akt. M."},
        { "fieldKey": "overTime", "fieldName": "gesetzl. ÜST akt. M."},

        { "fieldKey": "calcWorkTimeBalance", "fieldName": "Saldo +/- akt. M."},
        { "fieldKey": "calcHolidayTimeBalance", "fieldName": "Saldo FT akt. M."},
        { "fieldKey": "calcOverTimeBalance", "fieldName": "Saldo gesetzl. ÜST akt. M."},
        { "fieldKey": "vacationTimeBalance", "fieldName": "Saldo Urlaub akt. M."},

        { "fieldKey": "vacationWorkTime", "fieldName": "U. h akt. M."},
        { "fieldKey": "sickWorkTime", "fieldName": "K. h akt. M."},
        { "fieldKey": "wageContinuationWorkTime", "fieldName": "LFZ h akt. M."},
        { "fieldKey": "nursingVacationWorkTime", "fieldName": "PFL. h akt. M."},
        { "fieldKey": "educationWorkTime", "fieldName": "AUSB. h akt. M."},
      ];

      // console.log(view.monthlyStatementPerEmployee);

      // prep data
      for (let i in view.monthlyStatementPerEmployee) {
        let statements = view.monthlyStatementPerEmployee[i];
        for (let k in statements) {
          let statement = statements[k];
          for (let l in fields) {
            data[fields[l].fieldKey] = true;
          }
          if (view.previousMonthlyStatementPerEmployee && view.previousMonthlyStatementPerEmployee[statement.remoteId] !== undefined) {
            statement.prevMonth = view.previousMonthlyStatementPerEmployee[statement.remoteId].find(prevStatement => {
              return prevStatement.storeId === statement.storeId;
            });
          }
          data.balances.push(statement);
        }
      }

      let stores = [];
      let storesLabel = "";
      Object.values(view.storesMap).forEach(store => {
        if (view.selectedStores[store.id]) {
          stores.push(store);
          storesLabel += store.name + ", ";
        }
      });
      if (stores.length > 0) {
        storesLabel = storesLabel.substring(0, storesLabel.length - 2);
      }

      let superHeaders = [
        {content: '', colSpan: 1, styles: {halign: 'center', fillColor: [229,21,80]}},
        {content: 'Übersicht Vormonat', colSpan: 3, styles: {halign: 'center', fillColor: [229,21,80]}},
        {content: 'Übersicht Stunden akt. Monat', colSpan: 9, styles: {halign: 'center', fillColor: [229,21,80]}},
        {content: 'Unproduktive Stunden akt. Monat', colSpan: 5, styles: {halign: 'center', fillColor: [229,21,80]}},
      ];
      let subHeaders = ['Mitarbeiter'];
      let totalSums = {};
      let totalSumsPositive = {};
      let totalSumsNegative = {};
      let totalSumRow = ['Gesamt Summe'];
      let totalPosRow = ['Gesamt Summe Positiv'];
      let totalNegRow = ['Gesamt Summe Negativ'];

      Object.values(fields).forEach((element) => {
        this.addPdfReportHeader(subHeaders, data, element.fieldKey, element.fieldName, totalSums, totalSumsPositive, totalSumsNegative);
      });

      let filename = 'Arbeitszeitkonto_' + this.timeframeStart.format('YYYY-MM')+'.pdf';
      let body = [];
      let headers = [superHeaders, subHeaders];
      let repDoc = this.$helpers.tableReportDoc(
        subHeaders,
        'Arbeitszeitkonto ' + this.timeframeStart.format('MMMM YYYY'),
        view.$store.state.companyData.label,
        storesLabel,
        this.userFullname,
        9
      );
      let doc = repDoc.doc;
      let contentOffsetY = repDoc.contentOffsetY;
      doc.setFontSize(12);

      let balances = data.balances;
      balances.sort(function(a, b) {
        let ea = view.employeeMap[a.remoteId];
        let eb = view.employeeMap[b.remoteId];

        if(ea != undefined && eb != undefined){
          if(ea.lastName < eb.lastName) return - 1;
          if(ea.lastName > eb.lastName) return 1;
          if(ea.lastName == eb.lastName && ea.firstName < eb.firstName) return - 1;
          if(ea.lastName == eb.lastName && ea.firstName > eb.firstName) return 1;
        }

        return 0;
      });

      let balancesByFibu = {};
      for(let i = 0; i < balances.length; i++) {
        let bal = balances[i];
        let employee = view.employeeMap[bal.remoteId];
        if (employee === undefined) {
          continue;
        }
        let finAccGrpNumber =
            view.financialAccountingGroupsById[employee.financialAccountingGroupId] !== undefined ?
                view.financialAccountingGroupsById[employee.financialAccountingGroupId].number :
                null;
        if (!balancesByFibu.hasOwnProperty(finAccGrpNumber)) {
          balancesByFibu[finAccGrpNumber] = [];
        }
        balancesByFibu[finAccGrpNumber].push(bal);
      }

      for (const [fibuNumber, fibuBalances] of Object.entries(balancesByFibu)) {
        let sums = {};
        let sumsPositive = {};
        let sumsNegative = {};
        Object.values(fields).forEach((element) => {
          this.resetSums(element.fieldKey, sums, sumsPositive, sumsNegative);
        });

        let finAccGrpName =
            view.financialAccountingGroupsByNumber[fibuNumber] !== undefined ?
                view.financialAccountingGroupsByNumber[fibuNumber].name :
                "Nicht zugeordnet";
        body.push([finAccGrpName]);

        for(let i = 0; i < fibuBalances.length; i++){
          let bal = fibuBalances[i];
          let employee = view.employeeMap[bal.remoteId];
          if (employee === undefined) {
            continue;
          }
          let cur = [
            employee.lastName + " " + employee.firstName,
          ];

          // calc fields
          bal.prevMonthHolidayTime = bal.prevMonth !== undefined ? parseFloat(bal.prevMonth.holidayTimeBalance) : 0;
          bal.totalWorkTime = parseFloat(bal.workTime) + parseFloat(bal.unproductiveWorkTime);
          bal.calcWorkTimeBalance = parseFloat(bal.prevMonthTime) + parseFloat(bal.totalWorkTime) - parseFloat(bal.workTimeRegular);
          bal.calcHolidayTimeBalance = parseFloat(bal.prevMonthHolidayTime) + parseFloat(bal.holidayTime) - parseFloat(bal.holidayAdjustmentWorkTime);
          bal.calcOverTimeBalance = parseFloat(bal.prevMonthOverTime) + parseFloat(bal.overTime);

          Object.values(fields).forEach((element) => {
            if (element.fieldKey === "vacationTimeBalance") {
              this.addPdfReportColumnValue(cur, data, element.fieldKey, bal, sums, sumsPositive, sumsNegative, totalSums, totalSumsPositive, totalSumsNegative, 60*24, true);
            } else {
              this.addPdfReportColumnValue(cur, data, element.fieldKey, bal, sums, sumsPositive, sumsNegative, totalSums, totalSumsPositive, totalSumsNegative, 60, true);
            }
          });

          body.push(cur);
        }
        let sumRow = ['Summe'];
        let posRow = ['Summe Positiv'];
        let negRow = ['Summe Negativ'];

        Object.values(fields).forEach((element) => {
          if (element.fieldKey === "vacationTimeBalance") {
            this.addPdfReportSumValue(sumRow, posRow, negRow, data, element.fieldKey, sums, sumsPositive, sumsNegative, 60*24, true);
          } else {
            this.addPdfReportSumValue(sumRow, posRow, negRow, data, element.fieldKey, sums, sumsPositive, sumsNegative, 60, true);
          }
        });

        body.push(sumRow);
        body.push(posRow);
        body.push(negRow);
        body.push([]);
      }

      Object.values(fields).forEach((element) => {
        if (element.fieldKey === "vacationTimeBalance") {
          this.addPdfReportSumValue(totalSumRow, totalPosRow, totalNegRow, data, element.fieldKey, totalSums, totalSumsPositive, totalSumsNegative, 60*24, true);
        } else {
          this.addPdfReportSumValue(totalSumRow, totalPosRow, totalNegRow, data, element.fieldKey, totalSums, totalSumsPositive, totalSumsNegative, 60, true);
        }
      });

      body.push(totalSumRow);
      body.push(totalPosRow);
      body.push(totalNegRow);

      doc.setProperties({
        title: "Export Arbeitszeitkonto"
      });

      doc.autoTable(
        {
          startY: contentOffsetY,
          head: headers,
          body: body,
          headStyles: {
            fillColor: [229,21,80],
            fontSize: 7,
          },
          bodyStyles: {
            fontSize: 7
          },
          columnStyles: {
            0: {cellWidth: 48},
            1: {cellWidth: 13},
            2: {cellWidth: 13},
            3: {cellWidth: 13},
            4: {cellWidth: 13},
            5: {cellWidth: 13},
            6: {cellWidth: 13},
            7: {cellWidth: 13},
            8: {cellWidth: 13},
            9: {cellWidth: 13, fontStyle: 'bold'},
            10: {cellWidth: 13, fontStyle: 'bold'},
            11: {cellWidth: 13, fontStyle: 'bold'},
            12: {cellWidth: 13},
            13: {cellWidth: 13},
            14: {cellWidth: 13},
            15: {cellWidth: 13},
            16: {cellWidth: 13},
            17: {cellWidth: 13},
          },
          didParseCell: (data) => {
            if (data.section === 'body' && data.cell.raw !== undefined && data.cell.raw.includes("Summe")) {
              data.cell.styles.fontStyle = 'bold';
            }
            if (data.section === 'body' && data.cell.raw !== undefined && data.row.cells[1].raw === undefined) {
              data.cell.styles.fontStyle = 'bold';
            }
          },
          didDrawCell: (data) => {
            if (data.section === 'body' && (data.column.index === 0 || data.column.index === 3 || data.column.index === 12)) {
              const columnWidth = data.cell.width
              const rowHeight = data.cell.height;
              data.doc.setLineWidth(1.0);
              data.doc.line(data.cursor.x + columnWidth, data.cursor.y, data.cursor.x + columnWidth, data.cursor.y + rowHeight);
            }
          }
        }
    );

      this.modalPdfType = 'Report';
      this.previewPdf(doc, filename);
    },

    // sync settings
    loadExportSettings(mode) {
      let view = this;

      let url = "/api/sec/bmd/exports/settings/" + mode;
      view.$helpers.GetCall(url).then((response) => {
        if (response.data) {
          view.baseSettings.enableExportViaTransferService = response.data.enableExportViaTransferService;
          view.baseSettings.enablePeriodicExport = response.data.enablePeriodicExport;
          view.baseSettings.enableMonthlyClosingExport = !!response.data.enableMonthlyClosingExport;
          view.baseSettings.periodicExportTimes = response.data.periodicExportTimes;
          view.baseSettings.monthlyClosingExportSettings = response.data.monthlyClosingExportSettings ? response.data.monthlyClosingExportSettings : [];
          // set default for new property monthlyClosingRequired
          view.baseSettings.periodicExportTimes.forEach(time => {
            if (!time.hasOwnProperty("monthlyClosingRequired")) {
              time.monthlyClosingRequired = false;
            }
            if (!time.hasOwnProperty("months")) {
              time.months = [1,2,3,4,5,6,7,8,9,10,11,12];
            }
          });
          view.sExportSettings = response.data.exportSettings;

          view.baseSettingsBackup = JSON.parse(JSON.stringify(view.baseSettings));
          view.sExportSettingsBackup = JSON.parse(JSON.stringify(view.sExportSettings));
        }
        view.selectedSettingsId = view.sExportSettings.length > 0 ? view.sExportSettings[0].id : null;
      });
    },
    putExportSettings(mode, successmessage, errormessage) {
      let view = this;

      let url = "/api/sec/bmd/exports/settings/" + mode;
      let payload = {
        "enableExportViaTransferService": view.baseSettings.enableExportViaTransferService,
        "enablePeriodicExport": view.baseSettings.enablePeriodicExport,
        "enableMonthlyClosingExport": view.baseSettings.enableMonthlyClosingExport,
        "periodicExportTimes": view.baseSettings.periodicExportTimes,
        "monthlyClosingExportSettings": view.baseSettings.monthlyClosingExportSettings,
        "exportSettings": view.sExportSettings
      };
      return view.$helpers.EditCall(url, payload, null, successmessage, null, errormessage);
    },
    getPeriodicTimeTypeInputs(type, selectedValues=null) {
      switch(type) {
        case 1: return [
          { label: "Sonntags", value: 0, selected: selectedValues ? (Array.isArray(selectedValues) ? selectedValues.includes(0) : selectedValues === 0) : false },
          { label: "Montags", value: 1, selected: selectedValues ? (Array.isArray(selectedValues) ? selectedValues.includes(1) : selectedValues === 1) : false },
          { label: "Dienstags", value: 2, selected: selectedValues ? (Array.isArray(selectedValues) ? selectedValues.includes(2) : selectedValues === 2) : false },
          { label: "Mittwochs", value: 3, selected: selectedValues ? (Array.isArray(selectedValues) ? selectedValues.includes(3) : selectedValues === 3) : false },
          { label: "Donnerstags", value: 4, selected: selectedValues ? (Array.isArray(selectedValues) ? selectedValues.includes(4) : selectedValues === 4) : false },
          { label: "Freitags", value: 5, selected: selectedValues ? (Array.isArray(selectedValues) ? selectedValues.includes(5) : selectedValues === 5) : false },
          { label: "Samstags", value: 6, selected: selectedValues ? (Array.isArray(selectedValues) ? selectedValues.includes(6) : selectedValues === 6) : false }
        ];
        case 2:
          let items = [];
          for (let i = 0; i <= 30; i++) {
            items.push({ label: (i+1) + ". Tag des Monats", value: i, selected: selectedValues ? (Array.isArray(selectedValues) ? selectedValues.includes(i) : selectedValues === i) : false});
          }
          items.push({ label: "letzter Tag des Monats", value: 31, selected: selectedValues ? (Array.isArray(selectedValues) ? selectedValues.includes(31) : selectedValues === 31) : false});
          return items;
        default: return [];
      }
    },
    getPeriodicTimeLabel(time) {
      let view = this;
      let values = view.getPeriodicTimeTypeInputs(time.type);
      let label = "";

      let hours = parseInt(time.hours);
      if (hours > 23) {
        time.hours = 23;
      } else if (hours < 10) {
        time.hours = "0" + hours;
      } else {
        time.hours = hours;
      }

      let minutes = parseInt(time.minutes);
      if (minutes > 59) {
        time.minutes = 59;
      } else if (minutes < 10) {
        time.minutes = "0" + minutes;
      } else {
        time.minutes = minutes;
      }

      switch (time.type) {
        case 0: return time.hours + ":" + time.minutes;
        case 1:
          if (Array.isArray(time.dayOfWeek)) {
            if (time.dayOfWeek.length > 1) {
              label = "An " + time.dayOfWeek.length + " Tagen";
            } else {
              label = values[time.dayOfWeek[0]].label;
            }
          } else {
            label = values[time.dayOfWeek].label;
          }
          break;
        case 2:
          if (Array.isArray(time.dayOfMonth)) {
            if (time.dayOfMonth.length > 1) {
              label = "An " + time.dayOfMonth.length + " Tagen";
            } else {
              label = values[time.dayOfMonth[0]].label;
            }
          } else {
            label = values[time.dayOfMonth].label;
          }
          label += (time.months.length === 12 ? "" : (time.months.length > 1 ? " in " + time.months.length + " Monaten" : " im " + view.monthNames[time.months[0]-1]));
          break;
      }
      return label + " um " + time.hours + ":" + time.minutes;
    },
    getMonthlyClosingSettingMonths(time) {
      let view = this;

      if (time.months.length === 12) {
        return "Jedes Monat";
      } else {
        let label = "";
        Object.entries(view.monthNames).forEach((month) => {
          if (time.months.includes(parseInt(month[0])+1)) {
            label += month[1] + ", ";
          }
        });
        return label.substring(0, label.length - 2);
      }
    },
    openEditSettingsPopup() {
      let view = this;
      let setting = view.sExportSettings.find(s => s.id === view.selectedSettingsId);
      let dataEntries = [
          {label: 'Name', key: 'name', hidden: false, type: 'text', value: setting.name},
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          positiveButtonLabel: "Speichern",
          entries: dataEntries,
          selectCallback: function (entity) {
            setting.name = entity.name;
            view.settingsUpdated = true;
          },
          negativeButtonLabel: "Löschen",
          deleteCallback: function (entity) {
            if (view.baseSettings.enablePeriodicExport && view.baseSettings.enableExportViaTransferService && view.baseSettings.periodicExportTimes.find(t => t.exportId === view.selectedSettingsId)) {
              view.$helpers.warning("Warnung", "Die Export Einstellung wird in den periodischen Exportzeiten verwendet und kann daher nicht gelöscht werden.");
            } else if (view.baseSettings.enableMonthlyClosingExport && view.baseSettings.enableExportViaTransferService && view.baseSettings.monthlyClosingExportSettings.find(t => t.exportId === view.selectedSettingsId)) {
              view.$helpers.warning("Warnung", "Die Export Einstellung wird nach einem Monatsabschluss ausgeführt und kann daher nicht gelöscht werden.");
            } else {
              let index = view.sExportSettings.indexOf(setting);
              view.sExportSettings.splice(index, 1);
              view.selectedSettingsId = view.sExportSettings.length > 0 ? view.sExportSettings[0].id : null;
              view.settingsUpdated = true;
            }
          },
          errorCheck: {
          }
        },
        header: 'Export Einstellung bearbeiten',
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },
    openCreateNewSettingsPopup() {
      let view = this;
      let dataEntries = [
          {label: 'Name', key: 'name', hidden: false, type: 'text', value: ''},
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          positiveButtonLabel: "Anlegen",
          entries: dataEntries,
          selectCallback: function (entity) {
            let element = {
              id: crypto.randomUUID(),
              name: entity.name,
              availableFields: [],
              availableFieldsAdditional: [],
              selectedStores: [],
              selectedWageTypes: []
            };
            console.log(view.sExportSettings);
            view.sExportSettings.push(element);
            view.selectedSettingsId = element.id;
            view.settingsUpdated = true;
          },
          errorCheck: {
          }
        },
        header: 'Neue Export Einstellung anlegen',
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },
    openEditPeriodicTimePopup(time, newEntry=false) {
      let view = this;

      let exportSettingItems = view.sExportSettings.map(s => {
        return {value: s.id, label: s.name};
      });
      let months = Object.entries(view.monthNames).map(value => {
        return {value: parseInt(value[0]) + 1, label: value[1], selected: time.months.includes(parseInt(value[0]) + 1)};
      });

      let dataEntries = [
        {label: 'placeholder', key: 'placeholder', hidden: true, type: 'text', value: 'placeholder'},
        {
          label: 'Einstellung',
          key: 'exportId',
          hidden: false,
          type: 'dropdownsearch',
          value: time != null ? time.exportId : null,
          flexGroup: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: '',
            items: exportSettingItems,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: exportSettingItems.find(s => s.value === time.exportId),
            onceSelected: function ($event, item, searchbar) {
            },
            styleLight: true,
            placeholder: "Einstellung auswählen"
          }
        },
        {
          label: 'Export Monat',
          key: 'exportMonth',
          hidden: false,
          type: 'dropdownsearch',
          value: time != null ? time.exportMonth : null,
          flexGroup: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: '',
            items: view.periodicExportTimeReportMonth,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: view.periodicExportTimeReportMonth.find(s => s.value === time.exportMonth),
            onceSelected: function ($event, item, searchbar) {
            },
            styleLight: true,
            placeholder: "Exportmonat auswählen"
          }
        },
        {
          label: 'Zeitperiode',
          key: 'type',
          hidden: false,
          type: 'dropdownsearch',
          value: time != null ? time.type : null,
          flexGroup: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: '',
            items: view.periodicExportTimeTypes,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: view.periodicExportTimeTypes.find(s => s.value === time.type),
            onceSelected: function ($event, item, searchbar) {
            },
            styleLight: true,
            placeholder: "Zeitperiode auswählen"
          }
        },
        {
          label: 'Auswahl Monate',
          key: 'months',
          hidden: time != null ? time.type !== 2 : false,
          type: 'dropdownsearch',
          value: null,
          flexGroup: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: '',
            items: months,
            selectMultiple: true,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {
            },
            styleLight: true,
            placeholder: "Monate auswählen"
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let type = datafill.entries.find((i) => i.key === "type");
            let monthType = type.value === 2;
            entryBeforeUpdate.hidden = !monthType;
            return entryBeforeUpdate;
          },
        },
        {
          label: 'Tag des Monats',
          key: 'dayOfMonth',
          hidden: time != null ? time.type !== 2 : false,
          type: 'dropdownsearch',
          value: null,
          flexGroup: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: '',
            items: view.getPeriodicTimeTypeInputs(2, time.dayOfMonth),
            selectMultiple: true,
            selectedItems: [],
            selectedItem: [],
            onceSelected: function ($event, item, searchbar) {
            },
            styleLight: true,
            placeholder: "Tag auswählen"
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let type = datafill.entries.find((i) => i.key === "type");
            let monthType = type.value === 2;
            entryBeforeUpdate.hidden = !monthType;
            return entryBeforeUpdate;
          },
        },
        {
          label: 'Wochentag',
          key: 'dayOfWeek',
          hidden: time != null ? time.type !== 1 : false,
          type: 'dropdownsearch',
          value: null,
          flexGroup: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: '',
            items: view.getPeriodicTimeTypeInputs(1, time.dayOfWeek),
            selectMultiple: true,
            selectedItems: [],
            selectedItem: [],
            onceSelected: function ($event, item, searchbar) {
            },
            styleLight: true,
            placeholder: "Tag auswählen"
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let type = datafill.entries.find((i) => i.key === "type");
            let monthType = type.value === 1;
            entryBeforeUpdate.hidden = !monthType;
            return entryBeforeUpdate;
          },
        },
        {
          label: 'Export Zeit',
          key: 'timeframe',
          hidden: false,
          type: 'timeframe',
          value: view.$helpers.getMomentFromStringUTC("2024-01-01T" + time.hours + ":" + time.minutes + "Z"),
          flexGroup: false,
        },
        {
          label: 'Nur bei abgeschlossenem Monat exportieren',
          key: 'monthlyClosingRequired',
          hidden: false,
          type: 'bool',
          value: time.monthlyClosingRequired,
          flexGroup: false,
        },
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          positiveButtonLabel: "Übernehmen",
          entries: dataEntries,
          selectCallback: function (entity) {
            time.exportId = entity.exportId;
            time.exportMonth = entity.exportMonth;
            time.type = entity.type;
            time.months = entity.months;
            time.dayOfMonth = entity.dayOfMonth;
            time.dayOfWeek = entity.dayOfWeek;
            time.monthlyClosingRequired = entity.monthlyClosingRequired;
            time.hours = entity.timeframe.format('HH');
            time.minutes = entity.timeframe.format('mm');
            if (newEntry) {
              view.baseSettings.periodicExportTimes.push(time);
            }
            view.settingsUpdated = true;
          },
          errorCheck: {}
        },
        header: 'Periodische Exportzeit bearbeiten',
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },
    openEditMonthlyClosingExportPopup(time, newEntry=false) {
      let view = this;

      let exportSettingItems = view.sExportSettings.map(s => {
        return {value: s.id, label: s.name};
      });
      let months = Object.entries(view.monthNames).map(value => {
        return {value: parseInt(value[0]) + 1, label: value[1], selected: time.months.includes(parseInt(value[0]) + 1)};
      });

      let dataEntries = [
        {label: 'placeholder', key: 'placeholder', hidden: true, type: 'text', value: 'placeholder'},
        {
          label: 'Einstellung',
          key: 'exportId',
          hidden: false,
          type: 'dropdownsearch',
          value: time != null ? time.exportId : null,
          flexGroup: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: '',
            items: exportSettingItems,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: exportSettingItems.find(s => s.value === time.exportId),
            onceSelected: function ($event, item, searchbar) {
            },
            styleLight: true,
            placeholder: "Einstellung auswählen"
          }
        },
        {
          label: 'Auswahl Monate',
          key: 'months',
          hidden: false,
          type: 'dropdownsearch',
          value: null,
          flexGroup: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: '',
            items: months,
            selectMultiple: true,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {
            },
            styleLight: true,
            placeholder: "Monate auswählen"
          },
        },
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          positiveButtonLabel: "Übernehmen",
          entries: dataEntries,
          selectCallback: function (entity) {
            time.exportId = entity.exportId;
            time.months = entity.months;
            if (newEntry) {
              view.baseSettings.monthlyClosingExportSettings.push(time);
            }
            view.settingsUpdated = true;
          },
          errorCheck: {}
        },
        header: 'Export nach Monatsabschluss bearbeiten',
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },

    loadExportJobs() {
      let view = this;

      let url = ["monthly", "individual-wagetypes"].includes(view.$parent.mode) ? '/api/sec/bmd/exports/MFS/jobs'
        : this.$parent.mode == 'daily' ? '/api/sec/bmd/exports/AS/jobs'
        : '/api/sec/bmd/exports/PP/jobs';
      let baseurl = url;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseurl);

      this.axios({
        method: "get",
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then((response) => {
        view.exportJobs = response.data.data;
        console.log(response.data);

        Object.values(view.exportJobs).forEach((elem) => {
          elem.jobStartedISO = elem.jobStarted != null ? view.$helpers.convertUTCDateStringTZ(elem.jobStarted, 'YYYY-MM-DDTHH:mm:sssSZ') : null;
          elem.jobStarted = elem.jobStarted != null ? view.$helpers.convertUTCDateStringTZ(elem.jobStarted, 'DD.MM.YYYY HH:mm:ss') : null;
          elem.jobEnded = elem.jobEnded != null ? view.$helpers.convertUTCDateStringTZ(elem.jobEnded, 'DD.MM.YYYY HH:mm:ss') : null;
          elem.jobFailed = elem.jobFailed != null ? view.$helpers.convertUTCDateStringTZ(elem.jobFailed, 'DD.MM.YYYY HH:mm:ss') : null;
        });
      }).catch((e) => {

      });
    },
    downloadCSVFromExportJob(csv, date) {
      let view = this;
      let filename = 'Export_' + date + '.csv';
      view.$helpers.forceFileDownload(view.$helpers.b64DecodeUnicode(csv), filename);
    },
  },
};
</script>

<style scoped>
.export-builder {
  background: var(--bg);
  background: #f2f2f2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.export-builder > .header {
  width: calc(100% - 500px);
  box-shadow: 0 2px 0px -1px var(--contrast-2);
  margin-bottom: 20px;
}

.panel {
  align-self: center;
  width: 1400px;
  background: var(--bg);
  padding: 40px;
  padding-top: 95px;
  position: relative;
  margin-bottom: 20px;
}

.panel.side {
  width: 200px;
  padding: 20px 10px;
  position: absolute;
  right: -1620px;
  left: 0;
  top: 100px;
  margin: auto;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
}

.panel.side .entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.panel > .header {
  position: absolute;
  top: 40px;
  left: 40px;
  padding: 5px;
  width: calc(100% - 80px);
  display: inline-block;
  text-align: left;
  box-shadow: 0 2px 0px -1px var(--contrast-2);
  font-size: 22px !important;
  color: var(--contrast-4) !important;
  margin-left: 0;
}

.panel.settings .panel-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-around;
}

.panel .close {
  position: absolute;
  right: 20px;
  top: 20px;
}
.fa-stack.cancel {
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 5px;
  font-size: 10px;
  transform: scale(1);
}

.fa-stack.cancel .fa-circle {
  color: transparent;
  font-size: 22px;
  transform: translateY(2px);
}

.fa-stack.cancel .fa-times {
  font-size: 16px;
  transform: translateY(5px);
  color: var(--contrast-3);
}

.fa-stack.cancel:hover .fa-circle {
  color: var(--contrast-3);
}

.fa-stack.cancel:hover .fa-times {
  color: white;
}

.drag-marker {
  cursor: grab;
  user-select: none;
  position: absolute;
  left: 10px;
  top: 10px;
  color: var(--contrast-1);
}

.name-filter {
  position: absolute !important;
  width: 300px !important;
  box-shadow: inset 0 0 0 1px var(--contrast-3);
  top: 50px;
  right: 65px;
  z-index: 30;
}

.entryBox {
  width: 100%;
  font-size: 13px;
  text-align: left;
  padding: 2px 0px;
}

.entryBox .entryHeader {
  display: inline-block;
  width: 300px;
  color: #555;
  font-weight: 700;
}

.entryBox .entryHeaderLight {
  display: inline-block;
  color: #aaa;
  font-weight: 200;
}

.entryBox .entryData {
  display: inline-block;
  color: #aaa;
  font-weight: 200;
}
</style>
